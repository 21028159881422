export const columnFormats = {
    id: 'text',
    song_title: 'text',
    artist: 'text',
    ownership_info: 'percentage',
    side: 'text',
    service: 'text',
    channel: 'text',
    video_title: 'text',
    found_at: 'time',
    use_duration: 'duration',
    page_url: 'url',
    date_uploaded: 'date',
    archive_link: 'url',
    active: 'boolean',
    similarity: 'percentage',
    song_title_id: 'text',
    repost_group: 'text',
    screenshot: 'url',
    detections: 'text',
    foreign: 'text',
    domestic: 'text',
    account_id: 'text',
    client_id: 'text',
    sent_date: 'date',
    response_date: 'date',
    status: 'text',
    client_notes: 'text',
    internal_notes: 'text',

};

export const formatTypes = {
    date: {
        type: 'd',
        format: 'yyyy-mm-dd'
    },
    percentage: {
        type: 'n',
        format: '0.00%'
    },
    number: {
        type: 'n',
        format: '#,##0.00'
    },
    text: {
        type: 's',
        format: '@'
    },
    boolean: {
        type: 'b',
        format: 'General'
    },
    duration: {
        type: 's',
        format: '[h]:mm:ss'
    },
    time: {
        type: 's',
        format: 'hh:mm:ss'
    },
    url: {
        type: 's',
        format: '@'
    }
};
