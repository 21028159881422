import React, { useEffect } from 'react';

const useVideoEvents = (videoRef, src, setIsLoading) => {
    useEffect(() => {
        if (!videoRef.current) return;

        const video = videoRef.current;

        const handlers = {
            loadStart: () => {
                console.log(`[VideoPlayer] Load started for: ${src}`);
                setIsLoading(true);
            },
            canPlay: () => {
                console.log(`[VideoPlayer] Can play event for: ${src}`);
                setIsLoading(false);
            },
            // ... other handlers ...
        };

        // Attach events
        Object.entries(handlers).forEach(([event, handler]) => {
            video.addEventListener(event.toLowerCase(), handler);
        });

        return () => {
            // Cleanup events
            Object.entries(handlers).forEach(([event, handler]) => {
                video.removeEventListener(event.toLowerCase(), handler);
            });
        };
    }, [videoRef, src, setIsLoading]);
};

export default useVideoEvents; 