import { useState, useCallback, useEffect } from 'react';
import { Box, Grid, Typography, Button, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { pricingService } from 'services/api';
import { produce } from 'immer';
import { PieChart } from '@mui/x-charts/PieChart';
import { alpha } from '@mui/material/styles';
import { Snackbar } from '@mui/material';
import { Notes } from 'pages/Pricing/UserNotes';
import { DataTable } from 'components/common/DataTable';


export const usePricingManagement = ({ matter, selectedUseId, uses = [], setUses, clearSelectedUse, currentUser }) => {
    const userId = currentUser?.id;
    const [isPricingOpen, setIsPricingOpen] = useState(false);
    const theme = useTheme();
    const [orderedUseIds, setOrderedUseIds] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [latestReviews, setLatestReviews] = useState({
        associate_review_complete: false,
        partner_review_complete: false
    });
    const [selectedTab, setSelectedTab] = useState('notes');
    const [matterNotes, setMatterNotes] = useState([]);
    const [matterReview, setMatterReview] = useState([]);

    const handlePricingClose = useCallback(() => {
        setIsPricingOpen(false);
        clearSelectedUse();
    }, [clearSelectedUse]);

    const handleOrderChanged = useCallback((newOrder) => {
        console.log("New order:", newOrder);
        setOrderedUseIds(newOrder);
    }, []);

    const initializeOrderedIds = useCallback((uses) => {
        const initialOrderedIds = uses.map(use => use.id);
        setOrderedUseIds(initialOrderedIds);
    }, []);

    const handleUpdates = useCallback(async (updateType, updates) => {
        if (!userId) return;

        try {
            // Handle notes first
            if (updateType === "add_note") {
                // Optimistic update
                setMatterNotes(prevNotes => [...prevNotes, {
                    id: Date.now(), // temporary ID
                    matter_id: updates.matterId,
                    use_id: updates.useId,
                    note: updates.note,
                    date_created: new Date().toISOString(),
                    user_id: userId
                }]);

                // API call
                const response = await pricingService.addNote(updates.matterId, updates.useId, updates.note);
                return response;
            }

            const newUses = produce(uses, draft => {
                // Helper function to calculate valuation
                const calculateValuation = (use) => {
                    // Return null if required fields are missing, unless it's a special case
                    if (use.flag === 'Static Image') return 15000;
                    if (use.flag === 'Repost' || use.repost) return 5000;
                    // Add check for other flags
                    if (use.flag) return 0;
                    if (use.override !== null) return use.override;

                    // Return null if either popularity_rank or quality is missing
                    if (!use.popularity_rank || !use.quality) return null;

                    const durationOffer =
                        use.use_duration < '00:00:15' ? 3000 :
                            use.use_duration < '00:00:30' ? 3900 :
                                use.use_duration < '00:01:00' ? 4800 :
                                    use.use_duration < '00:02:00' ? 5400 : 6000;

                    const qualityOffer =
                        use.quality === 'LQ' ? 6750 :
                            use.quality === 'MQ' ? 10800 :
                                use.quality === 'HQ' ? 13500 : 0;

                    const popularityOffer =
                        use.popularity_rank === 'High' ? 13500 :
                            use.popularity_rank === 'Medium' ? 10800 :
                                use.popularity_rank === 'Low' ? 6750 : 0;

                    return durationOffer + qualityOffer + popularityOffer;
                };

                // Update the specific use and recalculate values
                const updateUseValues = (use) => {
                    const valuation = calculateValuation(use);
                    use.valuation = valuation;
                    use.prorated = valuation !== null ? valuation * (use.ownership_info || 0) : null;
                    use.priced = use.prorated !== null;
                };

                switch (updateType) {
                    case "popularity_rank":
                        draft.forEach(use => {
                            if (use.works_id === updates.works_id) {
                                use.popularity_rank = updates.popularity_rank;
                                updateUseValues(use);
                            }
                        });
                        break;


                    default:
                        const useIndex = draft.findIndex(use => use.id === updates.useId);
                        if (useIndex === -1) return;

                        switch (updateType) {
                            case "video_quality":
                                draft[useIndex].quality = updates.quality;
                                updateUseValues(draft[useIndex]);
                                break;
                            case "override":
                                draft[useIndex].override = updates.override;
                                draft[useIndex].valuation = updates.override;
                                draft[useIndex].prorated = updates.override * (draft[useIndex].ownership_info || 0);
                                break;
                            case "add_flag":
                                draft[useIndex].flag = updates.flag;
                                draft[useIndex].flags = [...(draft[useIndex].flags || []), updates.flag];
                                updateUseValues(draft[useIndex]);
                                break;
                        }
                }
            });

            // Update state immediately
            setUses(newUses);

            // Get only the required fields for valuation
            let useToUpdate;
            if (updateType === "popularity_rank") {
                useToUpdate = uses.find(use => use.works_id === updates.works_id);
            } else {
                const useIndex = uses.findIndex(use => use.id === updates.useId);
                useToUpdate = uses[useIndex];
            }

            if (!useToUpdate) return;

            const currentValues = {
                id: useToUpdate.id,
                opportunity_id: useToUpdate.opportunity_id,
                use_duration: useToUpdate.use_duration || useToUpdate.media_duration,
                quality: updateType === "video_quality" ? updates.quality : useToUpdate.quality,
                popularity_rank: updateType === "popularity_rank" ? updates.popularity_rank : useToUpdate.popularity_rank,
                flag: updateType === "add_flag" ? updates.flag : useToUpdate.flag,
                override: updateType === "override" ? updates.override : useToUpdate.override,
                ownership_info: useToUpdate.ownership_info,
                video_id: useToUpdate.video_id,
                works_id: useToUpdate.works_id,
            };

            // Make consolidated API call
            let response;
            if (updateType === "add_note") {
                response = await pricingService.addNote(updates.matterId, updates.useId, updates.note);
            } else {
                response = await pricingService.updateUse(
                    useToUpdate.id,
                    updateType,
                    currentValues
                );
            }

        } catch (error) {
            if (updateType === "add_note") {
                // Revert notes on error
                setMatterNotes(prevNotes => prevNotes.slice(0, -1)); // Remove the last added note
            } else {
                setUses(uses); // Existing revert logic
            }
            console.error(`Error updating ${updateType}:`, error);
            throw error;
        }
    }, [uses, setUses, userId]);

    const fetchLatestReviews = useCallback(async () => {
        try {
            const logs = await pricingService.getMatterReview(matter.id);
            const logsArray = Array.isArray(logs.data) ? logs.data : [];
            const sortedLogs = logsArray.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
            const latestPartnerLog = sortedLogs.find(log => log.review_type === 'Partner');
            const latestAssociateLog = sortedLogs.find(log => log.review_type === 'Associate');

            setLatestReviews({
                partner_review_complete: latestPartnerLog?.review_type || false,
                associate_review_complete: latestAssociateLog?.review_type || false
            });
        } catch (error) {
            console.error('Error fetching matter logs:', error);
        }
    }, [matter.id]);

    const handlePricingComplete = useCallback(async () => {
        // Check for valid role first
        if (!['Associate', 'Partner'].includes(currentUser.role)) {
            setSnackbarMessage('You must be an Associate or Partner to mark pricing as complete. You are currently logged in as ' + currentUser.role);
            setSnackbarOpen(true);
            return;
        }

        // Check for unpriced uses
        const unpricedUses = uses.filter(use => use.prorated === null);
        if (unpricedUses.length > 0) {
            setSnackbarMessage(`Cannot complete review: ${unpricedUses.length} use${unpricedUses.length === 1 ? '' : 's'} still need${unpricedUses.length === 1 ? 's' : ''} to be reviewed.`);
            setSnackbarOpen(true);
            return;
        }

        try {
            const pricedUses = uses.filter(use => use.prorated !== null);
            const flaggedUses = uses.filter(use => use.flags?.length > 0);
            const totalProposedOffer = pricedUses.reduce((sum, use) => sum + (use.prorated || 0), 0);

            // Optimistically update the review status
            setLatestReviews(prev => ({
                ...prev,
                associate_review_complete: currentUser.role === 'Associate' ? true : prev.associate_review_complete,
                partner_review_complete: currentUser.role === 'Partner' ? true : prev.partner_review_complete
            }));



            const logData = {
                opportunity_id: matter.id,
                proposed_offer: totalProposedOffer,
                percent_complete: (pricedUses.length / uses.length),
                number_of_uses: uses.length,
                priced: pricedUses.length,
                unpriced: unpricedUses.length,
                flags: flaggedUses.length,
                user_id: currentUser.id,
                review_complete: true,
            };

            await pricingService.addMatterReview(logData, matter.id);
            await fetchLatestReviews();

            // Add success message
            setSnackbarMessage(`Pricing review successfully completed by ${currentUser.role}`);
            setSnackbarOpen(true);
        } catch (error) {
            await fetchLatestReviews();
            console.error('Error completing pricing review:', error);
            setSnackbarMessage('Error completing pricing review. Please try again.');
            setSnackbarOpen(true);
            throw error;
        }
    }, [matter, uses, currentUser, fetchLatestReviews, setSnackbarMessage, setSnackbarOpen]);

    const fetchMatterNotes = useCallback(async () => {
        try {
            const response = await pricingService.getMatterNotes(matter.id);
            setMatterNotes(response.data || []);
        } catch (error) {
            console.error('Error fetching matter notes:', error);
        }
    }, [matter.id]);

    const fetchMatterReview = useCallback(async () => {
        try {
            const response = await pricingService.getMatterReview(matter.id);
            setMatterReview(response.data || []);
        } catch (error) {
            console.error('Error fetching matter review:', error);
        }
    }, [matter.id]);

    const getPricingInfoContent = useCallback(() => {
        const pricedUses = uses.filter(use => use.prorated !== null);
        const unpricedUses = uses.filter(use => use.prorated === null);
        const repostCount = uses.filter(use => use.repost).length;
        const totalProposedOffer = pricedUses.reduce((sum, use) => sum + (use.prorated || 0), 0);

        const pieData = [
            {
                id: 0,
                value: pricedUses.length,
                label: 'Priced Uses',
                color: theme.palette.primary.main
            },
            {
                id: 1,
                value: unpricedUses.length,
                label: 'Remaining Uses',
                color: theme.palette.grey[300]
            }
        ];

        return (
            <>
                <Box sx={{
                    p: 2.5,
                    background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
                    backdropFilter: 'blur(8px)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    mb: selectedTab ? 2 : 0
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="overline"
                                sx={{
                                    letterSpacing: '0.1em',
                                    color: alpha(theme.palette.text.primary, 0.7),
                                    fontSize: '0.75rem',
                                    fontWeight: 600
                                }}
                            >
                                PRICING REVIEW
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12} md={2}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Uses Priced
                                        </Typography>
                                        <Typography variant="h3" sx={{
                                            fontWeight: 700,
                                            lineHeight: 1,
                                            color: theme.palette.primary.main
                                        }}>
                                            {pricedUses.length}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Total Offer
                                        </Typography>
                                        <Typography variant="h3" sx={{
                                            fontWeight: 700,
                                            lineHeight: 1
                                        }}>
                                            ${totalProposedOffer.toLocaleString()}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Review Status
                                        </Typography>
                                        <Grid container spacing={1} justifyContent="center" direction="column">
                                            <Grid item>
                                                <Typography sx={{
                                                    color: latestReviews.associate_review_complete ? theme.palette.success.main : theme.palette.text.secondary,
                                                    fontSize: '0.875rem',
                                                    fontWeight: 600
                                                }}>
                                                    Attorney: {latestReviews.associate_review_complete ? '✓' : 'Pending'}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{
                                                    color: latestReviews.partner_review_complete ? theme.palette.success.main : theme.palette.text.secondary,
                                                    fontSize: '0.875rem',
                                                    fontWeight: 600
                                                }}>
                                                    Partner: {latestReviews.partner_review_complete ? '✓' : 'Pending'}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handlePricingComplete}
                                                    disabled={
                                                        (currentUser.role === 'Associate' && latestReviews.associate_review_complete) ||
                                                        (currentUser.role === 'Partner' && latestReviews.partner_review_complete)
                                                    }
                                                    sx={{ mt: 1 }}
                                                >
                                                    Mark Review Complete
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <Box sx={{ height: 160 }}>
                                        <PieChart
                                            series={[{
                                                data: pieData,
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                innerRadius: 60,
                                                paddingAngle: 2,
                                                cornerRadius: 4,
                                            }]}
                                            slotProps={{
                                                legend: {
                                                    direction: 'row',
                                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                                    padding: 0,
                                                    itemMarkWidth: 10,
                                                    itemMarkHeight: 10,
                                                    markGap: 5,
                                                    itemGap: 15,
                                                }
                                            }}
                                            height={160}
                                            margin={{ top: 10, bottom: 40, left: 0, right: 0 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, newValue) => setSelectedTab(newValue)}
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Notes" value="notes" />
                                <Tab label="Logs" value="logs" />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Box>

                {selectedTab && (
                    <Box sx={{
                        p: 2.5,
                        background: theme.palette.background.paper,
                        borderRadius: '16px',
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                        minHeight: '200px',
                        maxHeight: '400px',
                        overflow: 'auto'
                    }}>
                        {selectedTab === 'notes' && (
                            <Notes notes={matterNotes} theme={theme} />
                        )}
                        {selectedTab === 'logs' && (
                            <DataTable
                                data={matterReview}

                            />
                        )}
                    </Box>
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                />
            </>
        );
    }, [uses, theme, matter, currentUser, handlePricingComplete, snackbarOpen, snackbarMessage,
        latestReviews, selectedTab, matterNotes, matterReview]);

    useEffect(() => {
        fetchLatestReviews();
    }, [fetchLatestReviews]);

    useEffect(() => {
        if (selectedTab === 'notes') {
            fetchMatterNotes();
        } else if (selectedTab === 'logs') {
            fetchMatterReview();
        }
    }, [selectedTab, fetchMatterNotes, fetchMatterReview]);

    return {
        isPricingOpen,
        setIsPricingOpen,
        orderedUseIds,
        handlePricingClose,
        handleOrderChanged,
        initializeOrderedIds,
        getPricingInfoContent,
        handleUpdates,
        handlePricingComplete,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        fetchLatestReviews,
        selectedTab,
        setSelectedTab,
        fetchMatterNotes,
        fetchMatterReview,
    };
}; 