import { useState, useEffect, useMemo } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { titleAndReplace } from "utils/dataUtils";
import { useTheme } from 'themes/Palette';
import {
    Box,
    Button, Typography, Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';
import { convertToXLSX } from 'utils/exporters';
import { formatMoney, formatPercentage } from 'utils/dataUtils';
import { createLogger } from 'utils/debug-config';
const log = createLogger('DataTable');

export const defaultValueFormatter = (field, value) => {
    switch (field) {
        case 'settlement_amount':
        case 'user_offer':
        case 'current_offer':
        case 'floor':
        case "received_to_date":
        case "received":
        case "per_use":
        case "valuation":
        case "proposed_starting_offer":
        case "proposed_offer":
            return value !== null ? formatMoney.format(value) : null;
        case 'ownership_info':
        case 'similarity':
            return value !== null ? formatPercentage(value) : null;
        case 'email_date':
            return value !== null ? new Date(value).toISOString().split('T')[0] : null;
        default:
            return value;
    }
};

export const defaultTypes = (field) => {
    switch (field) {
        case 'settlement_amount':
        case 'user_offer':
        case 'current_offer':
        case 'floor':
        case 'number_of_uses':
        case 'released':
            return 'number';
        case 'date_uploaded':
        case 'date_modified':
        case 'received_on':
        case 'received_to_date':
        case 'term_start':
        case 'term_end':
            return 'date';
        case 'email_date':
            return 'dateTime';
        case 'reviewed':
        case 'rp_reviewed':
        case 'priced':
        case 'active':
        case 'online':
        case 'no_expiration':
            return 'boolean';
        default:
            return 'string';
    }
}
// Column Visibility Dialog component
function CoreDataGrid({
    data = [],
    onRowClick = null,
    rowsPerPage = 20,
    className = '',
    style = {},
    columns: initialColumns = [],
    showToolbar = true,
    pagination = true,
    allowColumnFilter = true,
    allowColumnSort = true,
    defaultVisibleColumns = null,
    trackOrderBy = null,
    onOrderChanged = null,
    exportFilename = 'export.xlsx',
}) {
    const { theme } = useTheme();
    const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns || []);

    // Generate columns configuration
    const columns = useMemo(() => {
        if (data.length > 0) {
            const cols = initialColumns.length > 0 ? initialColumns : Object.keys(data[0]);

            // Create a base columns map
            const columnsMap = cols.map((col) => ({
                field: col,
                headerName: titleAndReplace(col),
                headerAlign: 'left',
                align: 'left',
                flex: 1,
                minWidth: 150,
                filterable: allowColumnFilter,
                sortable: allowColumnSort,
                valueFormatter: (value) => defaultValueFormatter(col, value),
                type: defaultTypes(col),
                ...(defaultTypes(col) === 'boolean' && {
                    renderCell: (params) => (
                        params.value === null ? null : (
                            <Checkbox
                                checked={!!params.value}
                                disabled
                                size="small"
                                sx={{
                                    color: theme.palette.primary.main,
                                    '&.Mui-checked': {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            />
                        )
                    )
                })
            }));

            // If defaultVisibleColumns is provided, reorder columns accordingly
            if (defaultVisibleColumns) {
                const orderedColumns = [];
                // First add columns in the order specified in defaultVisibleColumns
                defaultVisibleColumns.forEach(field => {
                    const column = columnsMap.find(col => col.field === field);
                    if (column) {
                        orderedColumns.push(column);
                    }
                });
                // Then add any remaining columns that weren't in defaultVisibleColumns
                columnsMap.forEach(column => {
                    if (!defaultVisibleColumns.includes(column.field)) {
                        orderedColumns.push(column);
                    }
                });
                return orderedColumns;
            }

            return columnsMap;
        }
        return [];
    }, [data, initialColumns, allowColumnFilter, allowColumnSort, defaultVisibleColumns]);

    // Initialize visible columns
    useEffect(() => {
        if (defaultVisibleColumns === null) {
            setVisibleColumns(columns.map(col => col.field));
        }
    }, [columns, defaultVisibleColumns]);

    // Column visibility handlers
    const handleColumnToggle = (field) => {
        setVisibleColumns(prev =>
            prev.includes(field)
                ? prev.filter(f => f !== field)
                : [...prev, field]
        );
    };

    const handleSelectAll = () => {
        setVisibleColumns(columns.map(col => col.field));
    };

    const handleDeselectAll = () => {
        setVisibleColumns([]);
    };

    // Add sort change handler
    const handleSortModelChange = (sortModel) => {
        if (trackOrderBy && onOrderChanged && sortModel.length > 0) {
            const { field, sort } = sortModel[0];

            // Create a sorted copy of the data
            const sortedData = [...data].sort((a, b) => {
                const aValue = a[field];
                const bValue = b[field];

                // Handle null values
                if (aValue === null && bValue === null) return 0;
                if (aValue === null) return 1;
                if (bValue === null) return -1;

                // Compare values based on their type
                if (typeof aValue === 'string') {
                    return sort === 'asc'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                }

                return sort === 'asc'
                    ? aValue - bValue
                    : bValue - aValue;
            });

            // Extract tracked values in the new order
            const newOrder = sortedData.map(item => item[trackOrderBy]);
            onOrderChanged(newOrder);
        }
    };

    // Calculate the appropriate page size
    const effectivePageSize = pagination ? rowsPerPage : Math.min(100, data.length);
    const pageSizeOptions = pagination ? [5, 10, 20, 50, 100] : [effectivePageSize];

    return (
        <Box
            className={className}
            sx={{
                width: '100%',
                height: '100%',
                paddingTop: theme.spacing(1)
            }}
        >
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={(row) => row.id || Math.random()}
                onRowClick={onRowClick}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: effectivePageSize,
                        },
                    },
                    columns: {
                        columnVisibilityModel: defaultVisibleColumns
                            ? Object.fromEntries(
                                columns.map(col => [col.field, defaultVisibleColumns.includes(col.field)])
                            )
                            : undefined,
                    },
                }}
                pageSizeOptions={pageSizeOptions}
                slots={{
                    toolbar: showToolbar ? CustomToolbar : undefined,
                }}
                slotProps={{
                    toolbar: {
                        data: data,
                        visibleColumns: visibleColumns,
                        exportFilename: exportFilename
                    },
                }}
                autoHeight
                disableRowSelectionOnClick
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    ...style,
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: theme.palette.background.paper,
                    },
                }}
                onSortModelChange={handleSortModelChange}
                onColumnVisibilityModelChange={(newModel) => {
                    // Convert the visibility model to an array of visible column fields
                    const newVisibleColumns = Object.entries(newModel)
                        .filter(([_, isVisible]) => isVisible)
                        .map(([field]) => field);
                    setVisibleColumns(newVisibleColumns);
                }}
            />
        </Box>
    );
}

// Standard DataGrid component
function DataTable(props) {
    return <CoreDataGrid {...props} />;
}

// Grouped DataGrid component
function DataTableGrouped({
    data,
    groupBy,
    columns, // This can now be an object mapping groups to their column configs
    itemName = "item",
    initiallyExpanded = false,
    ...props
}) {
    const [expanded, setExpanded] = useState(new Set());

    const groupedData = useMemo(() => {
        return data.reduce((groups, item) => {
            const group = item[groupBy];
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(item);
            return groups;
        }, {});
    }, [data, groupBy]);

    useEffect(() => {
        if (initiallyExpanded) {
            setExpanded(new Set(Object.keys(groupedData)));
        }
    }, [initiallyExpanded, groupedData]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(prevExpanded => {
            const newExpanded = new Set(prevExpanded);
            if (isExpanded) {
                newExpanded.add(panel);
            } else {
                newExpanded.delete(panel);
            }
            return newExpanded;
        });
    };

    const itemCountText = (count) => {
        const plural = count === 1 ? itemName : `${itemName}s`;
        return `${count} ${plural}`;
    };

    return (
        <Box>
            {Object.entries(groupedData).map(([group, items]) => (
                <Accordion
                    key={group}
                    expanded={expanded.has(group)}
                    onChange={handleChange(group)}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="body1">
                            {titleAndReplace(group)} ({itemCountText(items.length)})
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <CoreDataGrid
                            data={items}
                            {...props}
                            columns={
                                typeof columns === 'object'
                                    ? columns[group]
                                    : columns
                            }
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
}

// Pivot DataGrid component
function PivotAGGrid(props) {
    const pivotGridOptions = {
        pivotMode: true,
        pivotPanelShow: 'always',
    };

    return <CoreDataGrid {...props} gridOptions={pivotGridOptions} />;
}

const ExportButton = ({ data, visibleColumns, exportFilename }) => {
    const handleExport = async () => {
        try {
            // Filter data to only include visible columns
            const filteredData = data.map(row => {
                const filteredRow = {};
                visibleColumns.forEach(field => {
                    filteredRow[field] = row[field];
                });
                return filteredRow;
            });

            const excelBuffer = await convertToXLSX(filteredData);
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportFilename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            log.error('Export failed:', error);
        }
    };

    return (
        <Button
            onClick={handleExport}
            startIcon={<DownloadIcon />}
            size="small"
        >
            Export
        </Button>
    );
};

function CustomToolbar(props) {
    const { theme } = useTheme();
    const { data, visibleColumns, exportFilename } = props;

    return (
        <GridToolbarContainer sx={{ padding: theme.spacing(1) }}>
            <ExportButton
                data={data}
                visibleColumns={visibleColumns}
                exportFilename={exportFilename}
            />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

export { DataTable, DataTableGrouped, PivotAGGrid };
