import { useState, useCallback, useEffect } from 'react';
import { repostService } from 'services/api';
import { createLogger } from 'utils/debug-config';
import { Box, Grid, Typography, Button, Tabs, Tab } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { DataTable } from 'components/common/DataTable';
const log = createLogger('UseRepostManagement');

export const useRepostManagement = ({ matter, uses, setUses, matterId, currentUser, selectedUseId, clearSelectedUse }) => {
    const [isRepostsOpen, setIsRepostsOpen] = useState(false);
    const theme = useTheme();
    const [processedReviews, setProcessedReviews] = useState(null);
    const [repostReviews, setRepostReviews] = useState({ review_complete: false });
    const [selectedTab, setSelectedTab] = useState('reviews');
    const [repostManualReviews, setRepostManualReviews] = useState([]);

    const filterAndSortUses = useCallback((usesData) => {
        // Count occurrences of each song_title_id
        const songTitleCounts = {};
        usesData.forEach(use => {
            songTitleCounts[use.song_title_id] = (songTitleCounts[use.song_title_id] || 0) + 1;
        });

        // Filter uses where song_title_id appears multiple times and sort them
        return usesData
            .filter(use => songTitleCounts[use.song_title_id] > 1)
            .sort((a, b) => {
                // First sort by repost_group nulls last
                if (a.repost_group !== b.repost_group) {
                    return a.repost_group === null ? 1 : b.repost_group === null ? -1 : 0;
                }
                // Then by song_title_id
                if (a.song_title_id !== b.song_title_id) {
                    return a.song_title_id - b.song_title_id;
                }
                // Then by date_uploaded
                if (a.date_uploaded !== b.date_uploaded) {
                    return new Date(a.date_uploaded) - new Date(b.date_uploaded);
                }
                // Finally by id
                return a.id - b.id;
            });
    }, []);

    const handleUpdateRepostGroup = useCallback(async ({ useId, oldGroupId, newGroupId }) => {
        if (!currentUser) {
            log.error('User not authenticated');
            throw new Error('User not authenticated');
        }

        if (!uses || !Array.isArray(uses)) {
            log.error('Uses data is invalid or not loaded');
            throw new Error('Uses data is invalid or not loaded');
        }

        try {
            const repostToUpdate = uses.find(repost => repost?.id === useId);
            if (!repostToUpdate) {
                log.error(`Repost not found for ID: ${useId}`);
                throw new Error('Repost not found');
            }

            const updatedRepost = {
                id: repostToUpdate.id,
                repost_group: newGroupId === "null" ? null : newGroupId,
                opportunity_id: matterId,
                repost: newGroupId !== "null",
                client_id: repostToUpdate.client_id,
                user_id: currentUser.id
            };

            await repostService.updateRepostGroup(updatedRepost);

            setUses(prevReposts =>
                Array.isArray(prevReposts)
                    ? prevReposts.map(repost =>
                        repost?.id === useId
                            ? { ...repost, repost_group: newGroupId }
                            : repost
                    )
                    : []
            );

            return true;
        } catch (error) {
            log.error('Failed to update repost group:', error);
            throw error;
        }
    }, [uses, matterId, currentUser, setUses]);

    const handleCloseReposts = useCallback(() => {
        setIsRepostsOpen(false);
        clearSelectedUse();
    }, [clearSelectedUse]);

    const fetchManualReviews = useCallback(async () => {
        if (matterId) {
            try {
                const response = await repostService.getMatterReviews(matterId);
                setRepostManualReviews(response.data || []);
            } catch (error) {
                log.error('Failed to fetch manual reviews:', error);
            }
        }
    }, [matterId]);

    useEffect(() => {
        const fetchReviews = async () => {
            if (matterId) {
                try {
                    // Fetch processed reviews
                    const processedResponse = await repostService.getMatterReviewsAuto(matterId);
                    setProcessedReviews(processedResponse.data);

                    // Fetch manual reviews
                    await fetchManualReviews();
                } catch (error) {
                    log.error('Failed to fetch reviews:', error);
                }
            }
        };

        fetchReviews();
    }, [matterId, fetchManualReviews]);

    const handleRepostReviewComplete = useCallback(async () => {
        if (!currentUser) {
            log.error('User not authenticated');
            throw new Error('User not authenticated');
        }

        try {
            const repostCount = uses.filter(use => use.repost).length;

            const matterReview = {
                opportunity_id: matterId,
                review_complete: true,
                date_created: new Date().toISOString(),
                user_id: currentUser.id,
                number_of_uses: uses.length,
                num_reposts: repostCount
            };

            await repostService.addMatterReview(matterReview);

            // Update the local state to reflect the change
            setRepostReviews(prev => ({
                ...prev,
                review_complete: true,
                date_created: new Date().toISOString(),
                number_of_uses: uses.length,
                num_reposts: repostCount
            }));

            // Refresh the manual reviews
            await fetchManualReviews();
        } catch (error) {
            log.error('Failed to mark repost review complete:', error);
            throw error;
        }
    }, [currentUser, matterId, uses, fetchManualReviews]);


    const getRepostInfoContent = useCallback(() => {
        const repostCount = uses.filter(use => use.repost).length;
        const reviewedCount = uses.filter(use => use.reviewed).length;
        const hasBeenProcessed = processedReviews && processedReviews.length > 0;
        const processingDate = hasBeenProcessed ? new Date(processedReviews[0].date_created) : null;

        const pieData = [
            {
                id: 0,
                value: reviewedCount,
                label: 'Reviewed',
                color: theme.palette.primary.main
            },
            {
                id: 1,
                value: uses.length - reviewedCount,
                label: 'Not Reviewed',
                color: theme.palette.grey[300]
            }
        ];

        // Check if there's a manual review from today
        const today = new Date();
        const hasReviewedToday = repostManualReviews.some(review => {
            const reviewDate = new Date(review.created_at);
            return reviewDate.toDateString() === today.toDateString();
        });

        return (
            <>
                <Box sx={{
                    p: 2.5,
                    background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
                    backdropFilter: 'blur(8px)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                    mb: selectedTab ? 2 : 0
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="overline"
                                sx={{
                                    letterSpacing: '0.1em',
                                    color: alpha(theme.palette.text.primary, 0.7),
                                    fontSize: '0.75rem',
                                    fontWeight: 600
                                }}
                            >
                                REPOST REVIEW
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12} md={2}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Total Uses
                                        </Typography>
                                        <Typography variant="h3" sx={{
                                            fontWeight: 700,
                                            lineHeight: 1
                                        }}>
                                            {uses.length}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={2}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Reposts
                                        </Typography>
                                        <Typography variant="h3" sx={{
                                            fontWeight: 700,
                                            lineHeight: 1,
                                            color: theme.palette.primary.main
                                        }}>
                                            {repostCount}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="caption" sx={{
                                            color: alpha(theme.palette.text.primary, 0.6),
                                            display: 'block',
                                            mb: 0.5
                                        }}>
                                            Review Status
                                        </Typography>
                                        <Grid container spacing={1} justifyContent="center" direction="column">
                                            {hasBeenProcessed && (
                                                <Grid item>
                                                    <Typography sx={{
                                                        color: theme.palette.success.main,
                                                        fontSize: '1rem',
                                                        fontWeight: 600
                                                    }}>
                                                        ✓ Auto Reviewed: {processingDate.toLocaleDateString()}
                                                    </Typography>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Typography sx={{
                                                    color: repostReviews.date_created ? theme.palette.success.main : theme.palette.text.secondary,
                                                    fontSize: '1rem',
                                                    fontWeight: 600
                                                }}>
                                                    {repostReviews.date_created
                                                        ? `✓ Manually Reviewed ${new Date(repostReviews.date_created).toLocaleDateString()}`
                                                        : 'Manual Review: Pending'
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleRepostReviewComplete}
                                                    disabled={hasReviewedToday}
                                                    sx={{ mt: 1 }}
                                                >
                                                    Mark Review Complete
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <Box sx={{ height: 160 }}>
                                        <PieChart
                                            series={[{
                                                data: pieData,
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                innerRadius: 60,
                                                paddingAngle: 2,
                                                cornerRadius: 4,
                                            }]}
                                            slotProps={{
                                                legend: {
                                                    direction: 'row',
                                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                                    padding: 0,
                                                    itemMarkWidth: 10,
                                                    itemMarkHeight: 10,
                                                    markGap: 5,
                                                    itemGap: 15,
                                                }
                                            }}
                                            height={160}
                                            margin={{ top: 10, bottom: 40, left: 0, right: 0 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, newValue) => setSelectedTab(newValue)}
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Logs" value="reviews" />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Box>

                {selectedTab && (
                    <Box sx={{
                        p: 2.5,
                        background: theme.palette.background.paper,
                        borderRadius: '16px',
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                        minHeight: '200px',
                        maxHeight: '400px',
                        overflow: 'auto'
                    }}>
                        {selectedTab === 'reviews' && (
                            repostManualReviews.length > 0 ? (
                                <DataTable data={repostManualReviews} />
                            ) : (
                                <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', mt: 2 }}>
                                    No past reviews available
                                </Typography>
                            )
                        )}
                    </Box>
                )}
            </>
        );
    }, [uses, theme, matter, processedReviews, repostReviews, handleRepostReviewComplete, selectedTab, repostManualReviews]);

    const handleUpdateRepostsReviewed = useCallback(async ({ useIds, isReviewed }) => {
        if (!currentUser) {
            log.error('User not authenticated');
            throw new Error('User not authenticated');
        }

        try {
            const reviewData = useIds.map(id => ({
                id,
                reviewed: isReviewed,
                user_id: currentUser.id
            }));

            await repostService.updateRepostsReviewed(reviewData);

            // Update local state
            setUses(prevUses =>
                prevUses.map(use =>
                    useIds.includes(use.id)
                        ? { ...use, reviewed: isReviewed }
                        : use
                )
            );

            return true;
        } catch (error) {
            log.error('Failed to update reposts reviewed status:', error);
            throw error;
        }
    }, [currentUser, matterId, setUses]);

    return {
        repostUses: filterAndSortUses(uses),
        isRepostsOpen,
        setIsRepostsOpen,
        handleUpdateRepostGroup,
        handleCloseReposts,
        getRepostInfoContent,
        handleUpdateRepostsReviewed,
        handleRepostReviewComplete,
        selectedTab,
        setSelectedTab,
        fetchManualReviews,
    };
}; 