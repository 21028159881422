import {
    Typography, Card, CardContent, Box, Grid, Alert
} from '@mui/material';
import { STEP_CONFIGS } from './reviewSteps';
import { formatDate } from './utils';

const ReviewSummary = ({ reviewData, corrections, item, submitStatus }) => {
    // Get all applicable fields based on agreement type
    const getApplicableFields = () => {
        const baseFields = STEP_CONFIGS.base;
        const typeSpecificFields = STEP_CONFIGS[item.category] || [];
        return [...baseFields, ...typeSpecificFields];
    };

    // Field configuration mapping
    const fieldConfig = {
        agreementType: {
            label: 'Agreement Type',
            original: item.category,
            correction: corrections.agreementType,
            status: reviewData.agreementTypeCorrect
        },
        matter: {
            label: 'Matter Information',
            original: item.matter_name,
            correction: corrections.matter?.name,
            status: reviewData.matterCorrect
        },
        effectiveDate: {
            label: 'Effective Date',
            original: formatDate(item.effective_date),
            correction: formatDate(corrections.effectiveDate),
            status: reviewData.effectiveDateCorrect
        },
        expirationDate: {
            label: 'Expiration Date',
            original: formatDate(item.expiration_date),
            correction: corrections.noExpiration ? 'No Expiration' : formatDate(corrections.expirationDate),
            status: reviewData.expirationDateStatus,
            additionalInfo: corrections.noExpiration ? 'Agreement does not expire' : null
        },
        paymentDate: {
            label: 'Payment Due Date',
            original: formatDate(item.payment_due),
            correction: formatDate(corrections.paymentDate),
            status: reviewData.paymentDueDateCorrect
        }
    };

    // Helper function to render status indicator
    const StatusIndicator = ({ isCorrect }) => (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: isCorrect ? '#4caf50' : '#ff9800',
                marginRight: 1
            }}
        />
    );

    // Helper function to format review item
    const ReviewItem = ({ label, original, correction, status, additionalInfo }) => {
        // Determine the final value that will be submitted
        const finalValue = (status === 'no' && correction) ? correction : original;
        // Only show correction history if there was a change
        const hasCorrection = status === 'no' && correction && original;

        return (
            <Card
                elevation={0}
                sx={{
                    mb: 2,
                    backgroundColor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                        transform: 'translateX(4px)'
                    }
                }}
            >
                <CardContent sx={{ p: 2 }}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                sx={{ textTransform: 'uppercase', letterSpacing: 0.5 }}
                            >
                                {label}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                {/* Final Value - Always Shown */}
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: hasCorrection ? 1 : 0 }}>
                                    <StatusIndicator isCorrect={!hasCorrection} />
                                    <Typography sx={{ fontWeight: 500 }}>
                                        {finalValue || 'Not Set'}
                                    </Typography>
                                </Box>
                                {/* Correction History */}
                                {hasCorrection && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                                        >
                                            Changed from: {original}
                                        </Typography>
                                    </Box>
                                )}
                                {additionalInfo && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 2, mt: 1 }}>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            {additionalInfo}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', p: 3 }}>
            <Typography
                variant="h4"
                sx={{
                    mb: 4,
                    fontWeight: 500,
                    color: 'text.primary',
                    borderBottom: '2px solid',
                    borderColor: 'primary.main',
                    pb: 1
                }}
            >
                Review Summary
            </Typography>

            {submitStatus?.success && (
                <Alert severity="success" sx={{ mb: 3 }}>
                    Review has been successfully saved!
                </Alert>
            )}

            {submitStatus?.error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                    {submitStatus.error}
                </Alert>
            )}

            {getApplicableFields().map(fieldName => {
                const config = fieldConfig[fieldName];
                return (
                    <ReviewItem
                        key={fieldName}
                        label={config.label}
                        original={config.original}
                        correction={config.correction}
                        status={config.status}
                        additionalInfo={config.additionalInfo}
                    />
                );
            })}
        </Box>
    );
};

export default ReviewSummary;