// DragAndDropArea.js
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import TollingAgreementFields from './TollingAgreementFields';
import SettlementAgreementFields from './SettlementAgreementFields';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createLogger } from 'utils/debug-config';

const DragAndDrop = ({ matterId, matterName }) => {
    const logger = createLogger('DragAndDrop');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const [file, setFile] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'error'
    });
    const theme = useTheme();

    useEffect(() => {
        let timer;
        if (snackbar.open) {
            timer = setTimeout(() => {
                setSnackbar(prev => ({ ...prev, open: false }));
            }, 5000); // Will hide after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [snackbar.open]);

    const validateFileType = (file) => {
        const validTypes = ['.pdf', '.docx'];
        const fileExtension = file.name.toLowerCase().slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity));
        return validTypes.includes(fileExtension);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        logger.info(`File dropped: ${droppedFile.name}`);

        if (!validateFileType(droppedFile)) {
            logger.warn('Invalid file type dropped');
            setSnackbar({
                open: true,
                message: 'Please upload only PDF or DOCX files',
                severity: 'error'
            });
            return;
        }

        setFile(droppedFile);
        setIsModalOpen(true);
        logger.debug('Modal opened via file drop');
    };

    const handleDocumentTypeChange = (type) => {
        logger.info(`Document type changed to: ${type}`);
        setDocumentType(type);
    };

    const handleClose = () => {
        logger.debug('Modal closed');
        setIsModalOpen(false);
        setDocumentType('');
        setFile(null);
    };

    const handleFileSelect = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            logger.info(`File selected: ${selectedFile.name}`);

            if (!validateFileType(selectedFile)) {
                logger.warn('Invalid file type selected');
                setSnackbar({
                    open: true,
                    message: 'Only PDF or DOCX files are accepted',
                    severity: 'error'
                });
                e.target.value = '';
                return;
            }

            setFile(selectedFile);
            setIsModalOpen(true);
            logger.debug('Modal opened via file selection');
            e.target.value = '';
        }
    };

    const renderFieldSet = () => {
        switch (documentType) {
            case 'TOLLING':
                return <TollingAgreementFields />;
            case 'SETTLEMENT':
                return <SettlementAgreementFields />;
            default:
                return null;
        }
    };

    return (
        <>
            <Box
                component="label"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 100,
                    border: `2px dashed ${theme.palette.primary.main}30`,
                    borderRadius: 2,
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                    '&:hover': {
                        backgroundColor: theme.palette.background.default,
                    },
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                />
                {snackbar.open ? (
                    <Alert
                        severity={snackbar.severity}
                        sx={{
                            width: '90%',
                            textAlign: 'center',
                            boxShadow: 'none',
                            backgroundColor: 'transparent',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {snackbar.message}
                    </Alert>
                ) : (
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                            width: '100%',
                            textAlign: 'center'
                        }}
                    >
                        Drag and drop files here
                    </Typography>
                )}
            </Box>
            <Modal
                isOpen={isModalOpen}
                onClose={handleClose}
                onDocumentTypeChange={handleDocumentTypeChange}
                matterId={matterId}
                matterName={matterName}
                file={file}
            >
                {renderFieldSet()}
            </Modal>
        </>
    );
};

export default DragAndDrop;