import { useState, useCallback } from 'react';
import { VIEW_MODES, BUTTON_CONFIG, REPOST_COLUMNS, DEFAULT_COLUMNS, PRICING_COLUMNS } from '../constants';
import { useSearchParams } from 'react-router-dom';

export const useViewManagement = ({ matterId, createTask, addNotification, scrollToTabs }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentView, setCurrentView] = useState(() => {
        const viewParam = searchParams.get("view");
        return viewParam && Object.values(VIEW_MODES).includes(viewParam)
            ? viewParam
            : VIEW_MODES.USES;
    });

    const shouldShowInfoSection = useCallback(() => {
        return [VIEW_MODES.REPOST, VIEW_MODES.PRICING].includes(currentView);
    }, [currentView]);

    const getTableColumns = useCallback(() => {
        switch (currentView) {
            case VIEW_MODES.REPOST:
                return REPOST_COLUMNS;
            case VIEW_MODES.PRICING:
                return PRICING_COLUMNS;
            default:
                return DEFAULT_COLUMNS;
        }
    }, [currentView]);

    const getTableStyle = useCallback(() => {
        const config = BUTTON_CONFIG.find(btn => btn.id === currentView);
        return {
            border: config ? `0px solid ${config.borderColor}` : 'none'
        };
    }, [currentView]);

    // This function is used to change the view and update the URL
    // Scrolls to the tabs section
    const handleViewChange = useCallback((newView) => {
        setCurrentView(newView);
        scrollToTabs();

        const tab = searchParams.get("tab");
        const newParams = { view: newView };
        if (tab) newParams.tab = tab;
        setSearchParams(newParams);

        if (newView === VIEW_MODES.UPDOWN) {
            if (!matterId) {
                console.error('matterId is not defined');
                addNotification('Error: Matter ID is not available', 'error');
                return;
            }
            createTask('updown', { matter_id: matterId });
        }
    }, [matterId, createTask, addNotification, scrollToTabs, searchParams, setSearchParams]);



    return {
        currentView,
        getTableColumns,
        getTableStyle,
        handleViewChange,
        shouldShowInfoSection
    };
}; 