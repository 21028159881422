import React, { useRef } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Slide,
  useScrollTrigger,
  Menu,
  MenuItem,
  IconButton
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import useNavLinks from "./hooks/useNavLinks";
import { useTheme } from "themes/Palette";
import { useNavBar } from "components/common/NavBar/NavBarContext";

function HideOnScroll({ children, disable }) {
  const trigger = useScrollTrigger({
    threshold: 0,
    disableHysteresis: true
  });

  if (disable) {
    return children;
  }

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const NavBar = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  const links = useNavLinks();
  const { isDarkMode, toggleTheme } = useTheme();
  const navbarRef = useRef(null);
  const { hideNavbar } = useNavBar();

  // Add state for managing dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (to) => {
    navigate(to);
    handleDropdownClose();
  };

  if (hideNavbar) {
    return <>{children}</>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <HideOnScroll disable={isHomePage}>
        <AppBar position="fixed" ref={navbarRef} color="transparent" elevation={0}>
          <Toolbar>
            {!isHomePage && (
              <>
                <Header
                  links={links}
                  isDarkMode={isDarkMode}
                  toggleTheme={toggleTheme}
                  onDropdownClick={handleDropdownClick}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleDropdownClose}
                  MenuListProps={{
                    'aria-labelledby': 'more-button',
                  }}
                >
                  {links
                    .find(link => link.type === 'dropdown')
                    ?.items.map((item) => (
                      <MenuItem
                        key={item.label}
                        onClick={() => handleMenuItemClick(item.to)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: 2,
          boxSizing: 'border-box',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default NavBar;