// Modal.js
import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Snackbar,
    Alert,
    Box,
} from '@mui/material';
import SettlementAgreementFields from './SettlementAgreementFields';
import TollingAgreementFields from './TollingAgreementFields';
import { useUploadMatterFile } from 'services/api';
import { useQueryClient } from '@tanstack/react-query';
import { StyledFormControl } from './FormFields';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { titleCase } from 'utils/dataUtils';

const Modal = ({ isOpen, onClose, onDocumentTypeChange, matterId, matterName, file }) => {
    const [documentType, setDocumentType] = useState('');
    const [formData, setFormData] = useState({});
    const queryClient = useQueryClient();
    const uploadMutation = useUploadMatterFile();
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        details: '',
        severity: 'success'
    });
    const [errors, setErrors] = useState({});
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);

    const handleDocumentTypeChange = (e) => {
        const type = e.target.value;
        setDocumentType(type);
        onDocumentTypeChange(type);
    };

    const validateForm = () => {
        const newErrors = {};

        // Basic document type validation
        if (!documentType) {
            newErrors.documentType = 'Document type is required';
        }

        // Validate form data based on document type
        if (documentType === 'SETTLEMENT') {
            if (!formData.effectiveDate) {
                newErrors.effectiveDate = 'Effective date is required';
            }
            if (!formData.executionStatus) {
                newErrors.executionStatus = 'Execution status is required';
            }
            if (!formData.releaseType) {
                newErrors.releaseType = 'Release type is required';
            }
        }

        if (documentType === 'TOLLING') {
            if (!formData.effectiveDate) {
                newErrors.effectiveDate = 'Effective date is required';
            }
            if (!formData.executionStatus) {
                newErrors.executionStatus = 'Execution status is required';
            }
            // Only validate expiration date if No Expiration is false
            if (!formData.noExpiration && !formData.expirationDate) {
                newErrors.expirationDate = 'Expiration date is required when No Expiration is not checked';
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        setHasAttemptedSubmit(true);
        if (!validateForm()) {
            setSnackbar({
                open: true,
                message: 'Please fill out all required fields',
                severity: 'error'
            });
            return;
        }
        if (file && matterId && matterName && documentType) {
            const metadata = {
                matterId: matterId,
                matter: matterName,
                filename: file.name,
                documentType: documentType,
                ...formData
            };

            const optimisticFile = {
                id: 'temp-' + Date.now(),
                matterId: matterId,
                matter: matterName,
                filename: file.name,
                category: documentType,
                status: 'PENDING',
                date: new Date().toISOString(),
                driveID: null,
                ...formData
            };

            try {
                // Convert the Blob response to JSON
                const response = await uploadMutation.mutateAsync(
                    { metadata, file },
                    {
                        onMutate: async () => {
                            await queryClient.cancelQueries(['matterFiles', matterId]);
                            const previousFiles = queryClient.getQueryData(['matterFiles', matterId]);
                            queryClient.setQueryData(['matterFiles', matterId], old => {
                                return [...(old || []), optimisticFile];
                            });
                            return { previousFiles };
                        },
                        onError: (err, newFile, context) => {
                            queryClient.setQueryData(['matterFiles', matterId], context.previousFiles);
                        },
                        onSettled: () => {
                            queryClient.invalidateQueries(['matterFiles', matterId]);
                        },
                    }
                );

                // Convert Blob to text and then parse as JSON
                const reader = new FileReader();
                reader.onload = () => {
                    const responseData = JSON.parse(reader.result);
                    setIsSubmitted(true);

                    // Create base submitted data with required fields
                    const submittedDataObj = {
                        'Category': documentType,
                        'File Name': file.name,
                    };

                    // Add non-null form data with title cased keys
                    Object.entries(formData).forEach(([key, value]) => {
                        if (value != null) {
                            submittedDataObj[titleCase(key)] = value;
                        }
                    });

                    setSubmittedData(submittedDataObj);
                };
                reader.readAsText(response);

            } catch (error) {
                if (error instanceof Blob) {
                    // Handle blob error response
                    const reader = new FileReader();
                    reader.onload = () => {
                        const errorData = JSON.parse(reader.result);
                        setSnackbar({
                            open: true,
                            message: errorData.message,
                            details: errorData.detail || '',
                            severity: 'error'
                        });
                    };
                    reader.readAsText(error);
                } else {
                    // Handle regular error response
                    const errorMessage = error?.response?.content?.message;
                    const errorDetail = error?.response?.content?.detail;

                    setSnackbar({
                        open: true,
                        message: errorMessage || 'Upload failed',
                        details: errorDetail || '',
                        severity: 'error'
                    });
                }
            }
        }
    };

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setIsSubmitted(false);
            setSubmittedData(null);
        }, 300);
    };

    const handleSnackbarClose = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Document Form</DialogTitle>
            {!isSubmitted ? (
                <>
                    <DialogContent sx={{ pb: 8 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1,
                                '& strong': {
                                    color: 'text.primary'
                                }
                            }}
                        >
                            <strong>Matter:</strong> {matterName}
                        </Typography>
                        {file && (
                            <Typography
                                variant="body2"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    mb: 2,
                                    '& strong': {
                                        color: 'text.primary'
                                    },
                                    '& span': {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        flex: 1
                                    }
                                }}
                            >
                                <strong>Filename:</strong> <span>{file.name}</span>
                            </Typography>
                        )}

                        <StyledFormControl fullWidth>
                            <InputLabel id="document-type-label">Document Type</InputLabel>
                            <Select
                                labelId="document-type-label"
                                value={documentType}
                                onChange={handleDocumentTypeChange}
                                label="Document Type"
                                error={hasAttemptedSubmit && !!errors.documentType}
                                required
                            >
                                <MenuItem value="">Select a document type</MenuItem>
                                <MenuItem value="TOLLING">Tolling Agreement</MenuItem>
                                <MenuItem value="SETTLEMENT">Settlement Agreement</MenuItem>
                            </Select>
                            {hasAttemptedSubmit && errors.documentType && (
                                <Typography color="error" variant="caption" sx={{ mt: 0.5, ml: 1.75 }}>
                                    {errors.documentType}
                                </Typography>
                            )}
                        </StyledFormControl>
                        {documentType === 'TOLLING' && (
                            <TollingAgreementFields
                                onDataChange={(data) => setFormData(data)}
                                errors={hasAttemptedSubmit ? errors : {}}
                            />
                        )}
                        {documentType === 'SETTLEMENT' && (
                            <SettlementAgreementFields
                                onDataChange={(data) => setFormData(data)}
                                errors={hasAttemptedSubmit ? errors : {}}
                            />
                        )}

                        <Snackbar
                            open={snackbar.open}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            sx={{ position: 'absolute', bottom: 16 }}
                        >
                            <Alert
                                onClose={handleSnackbarClose}
                                severity={snackbar.severity}
                                sx={{ width: '100%', backgroundColor: 'inherit', color: 'inherit' }}
                            >
                                <div>
                                    <Typography>{snackbar.message}</Typography>
                                    {snackbar.details && (
                                        <Typography variant="body2" sx={{ mt: 0.5, opacity: 0.8 }}>
                                            {snackbar.details}
                                        </Typography>
                                    )}
                                </div>
                            </Alert>
                        </Snackbar>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button onClick={handleSave}>Save</Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            py={3}
                        >
                            <CheckCircleOutlineIcon
                                color="success"
                                sx={{ fontSize: 60, mb: 2 }}
                            />
                            <Typography variant="h6" gutterBottom>
                                File Uploaded Successfully!
                            </Typography>
                            <Typography color="text.secondary" align="center" gutterBottom>
                                Your file has been processed with the following details:
                            </Typography>
                            {submittedData && (
                                <Box sx={{ width: '100%', mt: 2 }}>
                                    {Object.entries(submittedData).map(([key, value]) => (
                                        <Typography key={key} variant="body2" sx={{ mb: 1 }}>
                                            <strong>{key}:</strong> {value}
                                        </Typography>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

export default Modal;