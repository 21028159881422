import {
    Box,
    Button,
    CardContent,
    Step,
    StepLabel,
    Stepper
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { dashboardService, matterService } from 'services/api';
import LoadingSpinner from 'utils/LoadingSpinner';
import { CompleteButton } from '../../utils/CompleteButton';
import DetailView from '../DetailView';
import { useTimelineContext } from '../../TimelineContext';
import { ReviewProvider, useReview } from './ReviewContext';
import { useStepConfig } from './useStepConfig';
import ReviewStart from './ReviewStart';
import ReviewStep from './ReviewStep';
import ReviewSummary from './ReviewSummary';
import { stepperStyles } from './styles';
import { combineTextByParagraphClass, formatStepLabel } from './utils';

const AgreementReviewContent = ({ item, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [preambleText, setPreambleText] = useState('');
    const [paymentText, setPaymentText] = useState('');
    const [clauseText, setClauseText] = useState('');
    const [matters, setMatters] = useState([]);
    const { handleComplete } = useTimelineContext();
    const {
        reviewData,
        corrections,
        activeStep,
        reviewStarted,
        submitStatus,
        handleStepUpdate,
        handleCorrectionChange,
        handleBack,
        handleStartReview,
        setSubmitStatus,
        resetReview
    } = useReview();

    const { stepConfigs, steps } = useStepConfig({
        item,
        preambleText,
        paymentText,
        matters,
        reviewData,
        corrections,
        onStepUpdate: handleStepUpdate,
        onCorrectionChange: handleCorrectionChange
    });

    useEffect(() => {
        setPreambleText(combineTextByParagraphClass(item.file_md, 'PREAMBLE'));
        setPaymentText(combineTextByParagraphClass(item.file_md, 'SETTLEMENT_PAYMENT'));
        setClauseText(combineTextByParagraphClass(item.file_md, 'CLAUSE'));
    }, [item.file_md]);

    useEffect(() => {
        const fetchMatters = async () => {
            try {
                const response = await matterService.getAllMatters();
                setMatters(response.data);
            } catch (error) {
                console.error('Failed to fetch matters:', error);
            }
        };
        fetchMatters();
    }, []);

    useEffect(() => {
        resetReview();
        setPreambleText(combineTextByParagraphClass(item.file_md, 'PREAMBLE'));
        setPaymentText(combineTextByParagraphClass(item.file_md, 'SETTLEMENT_PAYMENT'));
        setClauseText(combineTextByParagraphClass(item.file_md, 'CLAUSE'));
    }, [item, resetReview]);

    const handleReviewSubmit = async () => {
        setLoading(true);
        try {
            const formatDate = (date) => {
                if (!date) return null;
                return new Date(date).toISOString().split('T')[0];
            };

            const review = [
                {
                    field: 'agreement_type',
                    extracted: item.category || null,
                    correct: reviewData.agreementTypeCorrect === 'yes',
                    correction: reviewData.agreementTypeCorrect === 'no' ? corrections.agreementType : null
                },
                {
                    field: 'matter_id',
                    extracted: item.matter_id || null,
                    correct: reviewData.matterCorrect === 'yes',
                    correction: reviewData.matterCorrect === 'no' ? corrections.matter?.id : null,
                },
                {
                    field: 'matter',
                    extracted: item.matter_name || null,
                    correct: reviewData.matterCorrect === 'yes',
                    correction: reviewData.matterCorrect === 'no' ? corrections.matter?.name : null
                },
                {
                    field: 'effective_date',
                    extracted: item.effective_date || null,
                    correct: reviewData.effectiveDateCorrect === 'yes',
                    correction: reviewData.effectiveDateCorrect === 'no' ? formatDate(corrections.effectiveDate) : null
                },
                {
                    field: 'expiration_date',
                    extracted: item.expiration_date || null,
                    correct: reviewData.expirationDateStatus === 'yes',
                    correction: reviewData.expirationDateStatus === 'no' ? formatDate(corrections.expirationDate) : null
                },
                {
                    field: 'payment_due',
                    extracted: item.payment_due || null,
                    correct: reviewData.paymentDueDateCorrect === 'yes',
                    correction: reviewData.paymentDueDateCorrect === 'no' ? formatDate(corrections.paymentDate) : null
                }
            ];

            // First submit the review
            const reviewResponse = await dashboardService.submitAgreementReview(item.file_id, { review });

            // Only if review submission is successful, mark the task as complete
            if (reviewResponse) {
                try {
                    await handleComplete(item.id);
                    setSubmitStatus({ success: true, error: null });
                } catch (completeError) {
                    console.error('Error completing task:', completeError);
                    setSubmitStatus({
                        success: false,
                        error: 'Review submitted but failed to mark task as complete'
                    });
                }
            }
        } catch (apiError) {
            console.error('API Error:', apiError);
            setSubmitStatus({
                success: false,
                error: apiError.response?.status === 500
                    ? 'Server error occurred. Please try again.'
                    : apiError.message === 'Network Error'
                        ? 'Unable to connect to the server. Please check your internet connection and try again.'
                        : apiError.message || 'Failed to submit review'
            });
        } finally {
            setLoading(false);
        }
    };

    const getStepContent = (step) => {
        if (step === steps.length) {
            return (
                <>
                    <ReviewSummary
                        reviewData={reviewData}
                        corrections={corrections}
                        item={item}
                        submitStatus={submitStatus}
                    />
                    <CompleteButton
                        text="Complete Review"
                        onClick={handleReviewSubmit}
                        disabled={submitStatus.success}
                    />
                </>
            );
        }

        const stepConfig = stepConfigs[steps[step]];
        if (!stepConfig) return null;

        if (loading) {
            return <LoadingSpinner />;
        }

        return <ReviewStep {...stepConfig} driveUrl={item.url} />;
    };

    return (
        <CardContent sx={stepperStyles}>
            {!reviewStarted ? (
                <ReviewStart
                    item={item}
                    onStartReview={handleStartReview}
                />
            ) : (
                <>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{formatStepLabel(label)}</StepLabel>
                            </Step>
                        ))}
                        <Step>
                            <StepLabel>Summary</StepLabel>
                        </Step>
                    </Stepper>
                    <Box sx={{ mt: 4, minHeight: '300px' }}>
                        {getStepContent(activeStep)}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                    </Box>
                </>
            )}
        </CardContent>
    );
};

const AgreementReviewDetail = ({ item, onClose }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DetailView onClose={onClose} item={item}>
                <ReviewProvider item={item}>
                    <AgreementReviewContent item={item} onClose={onClose} />
                </ReviewProvider>
            </DetailView>
        </LocalizationProvider>
    );
};

AgreementReviewDetail.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        category: PropTypes.string,
        matter_name: PropTypes.string,
        effective_date: PropTypes.string,
        expiration_date: PropTypes.string,
        payment_due: PropTypes.string,
        file_md: PropTypes.array,
        url: PropTypes.string
    }).isRequired,
    onClose: PropTypes.func
};

export default memo(AgreementReviewDetail);
