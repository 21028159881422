import { useState, useEffect, useCallback, useRef } from 'react';
import { TaskService } from 'services/TaskService';
import { convertToXLSX } from 'utils/exporters';
import { useNotifications } from 'components/common/Notifications/NotificationContext';
import { formatDateTime, calculateDuration } from 'utils/dataUtils';
import { createLogger } from 'utils/debug-config';

const log = createLogger('TaskManagement');

const useTaskManagement = () => {
    const [kwargs, setKwargs] = useState({});
    const [taskType, setTaskType] = useState('ten_seconds');
    const [activeTasks, setActiveTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const taskConnectionsRef = useRef({});
    const { addNotification } = useNotifications();


    const executeAction = useCallback(async (task) => {
        try {
            const xlsxData = await convertToXLSX(task.result.data);
            const blob = new Blob([xlsxData], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = task.result.data_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            log.info(`Exported report for task ${task.job_id}`);
        } catch (error) {
            log.error(`Error exporting report for task ${task.job_id}:`, error);
            addNotification({
                type: 'error',
                message: `Failed to export report for task ${task.job_id}`,
                action: () => log.info(`Failed export notification clicked for task ${task.job_id}`)
            });
            throw error;
        }
    }, [addNotification]);

    const handleTaskCompletion = useCallback((type, task, status) => {
        const taskHandlers = {
            updown: {
                created: () => {
                    addNotification({
                        type: 'info',
                        message: 'Up down task submitted',
                        action: () => log.info('Up down task notification clicked')
                    });
                },
                complete: () => {
                    addNotification({
                        type: 'success',
                        message: `${task?.function || 'Task'} is complete`,
                        task: task,
                        action: () => executeAction(task)
                    });
                    // Add page reload after task completion
                    window.location.reload();
                }
            },
            export_report: {
                created: () => {
                    // No notification for export_report creation
                },
                complete: () => {
                    // Direct execution without notification
                    executeAction(task);
                }
            }
        };

        const handler = taskHandlers[type]?.[status];
        if (handler) {
            handler();
        }
    }, [addNotification, executeAction]);

    const handleTaskUpdate = useCallback((taskId, taskType, kwargs, data) => {
        const isTerminalState = ['complete', 'failed', 'error', 'not_found'].includes(data.status);

        if (isTerminalState) {
            setActiveTasks(prev => prev.filter(task => task.id !== taskId));
            setCompletedTasks(prev => [...prev, {
                id: taskId,
                type: taskType,
                kwargs: kwargs,
                status: data.status,
                result: data.result || 'Done',
                error: data.error,
                created: formatDateTime(data.enqueue_time),
                completed: formatDateTime(data.finish_time) || formatDateTime(new Date()),
                duration: calculateDuration(data.enqueue_time, data.finish_time || new Date())
            }]);

            if (data.status === 'complete') {
                handleTaskCompletion(taskType, data, 'complete');
                // Removed separate executeAction call since it's now handled in the notification action
            }

            // Cleanup connection
            if (taskConnectionsRef.current[taskId]) {
                taskConnectionsRef.current[taskId].disconnect();
                delete taskConnectionsRef.current[taskId];
            }
        } else {
            setActiveTasks(prev => prev.map(task =>
                task.id === taskId ? { ...task, status: data.status } : task
            ));
        }
    }, [handleTaskCompletion]); // Updated dependencies

    const createTask = useCallback(async (taskType, kwargs) => {
        const taskInput = {
            task_name: taskType,
            kwargs: kwargs,
        };

        try {
            const response = await TaskService.createTask(taskInput);
            const taskId = response.data.id;

            // Add creation notification
            handleTaskCompletion(taskType, null, 'created');

            const newTask = {
                id: taskId,
                type: taskType,
                kwargs: kwargs,
                status: response.data.status,
            };

            setActiveTasks(prev => [...prev, newTask]);

            // Create WebSocket connection using new TaskService
            const connection = TaskService.createWebSocketConnection(taskId, {
                onOpen: () => {
                    log.debug(`WebSocket connected for task ${taskId}`);
                },
                onMessage: ({ data }) => {
                    log.debug('Received WebSocket message:', data);
                    handleTaskUpdate(taskId, taskType, kwargs, data);
                },
                onError: ({ error }) => {
                    log.error(`WebSocket error for task ${taskId}:`, error);
                    handleTaskUpdate(taskId, taskType, kwargs, {
                        status: 'error',
                        error: error.message,
                        enqueue_time: new Date(),
                        finish_time: new Date(),
                    });
                },
                onClose: () => {
                    log.debug(`WebSocket closed for task ${taskId}`);
                    // Only handle as error if task isn't already completed
                    if (activeTasks.some(task => task.id === taskId)) {
                        handleTaskUpdate(taskId, taskType, kwargs, {
                            status: 'error',
                            error: 'Connection closed unexpectedly',
                            enqueue_time: new Date(),
                            finish_time: new Date(),
                        });
                    }
                },
            });

            // Store connection reference
            taskConnectionsRef.current[taskId] = connection;
        } catch (error) {
            log.error('Error creating task:', error);
            addNotification({
                type: 'error',
                message: 'Failed to create task',
                action: () => log.info('Failed task creation notification clicked'),
            });
        }
    }, [activeTasks, handleTaskUpdate, addNotification, handleTaskCompletion]);

    // Cleanup all connections on unmount
    useEffect(() => {
        return () => {
            Object.values(taskConnectionsRef.current).forEach(connection => {
                connection.disconnect();
            });
            taskConnectionsRef.current = {};
        };
    }, []);

    return {
        kwargs,
        setKwargs,
        taskType,
        setTaskType,
        createTask,
        activeTasks,
        completedTasks
    };
};

export default useTaskManagement;
