import React from 'react';
import { TextField, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& .MuiInputLabel-root': {
        color: theme.palette.text.primary
    },
    '& .MuiInputLabel-shrink': {
        color: theme.palette.text.primary
    }
}));

export const DateField = ({ label, value, onChange, error, helperText, required, ...props }) => {
    return (
        <StyledFormControl>
            <TextField
                label={label}
                type="date"
                value={value}
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                error={error}
                helperText={helperText}
                required={required}
                {...props}
            />
        </StyledFormControl>
    );
};

export const ExecutionStatusField = ({ value, onChange, error, helperText, required, ...props }) => {
    return (
        <StyledFormControl>
            <TextField
                label="Execution Status"
                select
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                {...props}
            >
                <MenuItem value="Fully Executed">Fully Executed</MenuItem>
                <MenuItem value="Partially Executed">Partially Executed</MenuItem>
                <MenuItem value="Unsigned">Unsigned</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
            </TextField>
        </StyledFormControl>
    );
};

export const ReleaseTypeField = ({ value = "Limited", onChange, ...props }) => {
    return (
        <StyledFormControl>
            <TextField
                label="Release Type"
                select
                value={value}
                onChange={onChange}
                {...props}
            >
                <MenuItem value="General">General</MenuItem>
                <MenuItem value="Limited">Limited</MenuItem>
            </TextField>
        </StyledFormControl>
    );
};

export const PricingField = ({ value, onChange, error, helperText, required, ...props }) => {
    return (
        <StyledFormControl>
            <TextField
                label="Pricing"
                value={value}
                onChange={onChange}

                {...props}
            />
        </StyledFormControl>
    );
};

export const PaymentDueField = ({ value, onChange, error, helperText, required, ...props }) => {
    return (
        <StyledFormControl>
            <TextField
                label="Payment Due"
                type="date"
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                InputLabelProps={{ shrink: true }}
                {...props}
            />
        </StyledFormControl>
    );
};


export const NoExpirationField = ({ value, onChange, ...props }) => {
    return (
        <StyledFormControl>
            <FormControlLabel
                control={
                    <Checkbox checked={value} onChange={onChange} {...props} />
                }
                label="No Expiration"
            />
        </StyledFormControl>
    );
};
