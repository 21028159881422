import { createContext, useContext, useState, useCallback } from 'react';
import { INITIAL_REVIEW_DATA } from './reviewSteps';

const ReviewContext = createContext(null);

export const ReviewProvider = ({ children, item }) => {
    const [reviewData, setReviewData] = useState(INITIAL_REVIEW_DATA);
    const [corrections, setCorrections] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [reviewStarted, setReviewStarted] = useState(false);
    const [submitStatus, setSubmitStatus] = useState({ success: false, error: null });

    const handleStepUpdate = useCallback((fieldName, value, shouldAdvance = false) => {
        setReviewData(prev => ({
            ...prev,
            [fieldName]: value
        }));

        // For agreement type with auto-switch
        if (fieldName === 'agreementTypeCorrect' && value === 'no') {
            const newType = item.category === 'SETTLEMENT' ? 'TOLLING' : 'SETTLEMENT';
            handleCorrectionChange('agreementType', newType);
        }

        if (value === 'yes' || shouldAdvance) {
            handleNext();
        }
    }, [item.category]);

    const handleCorrectionChange = useCallback((fieldName, value) => {
        setCorrections(prev => ({
            ...prev,
            [fieldName]: value
        }));
    }, []);

    const handleNext = useCallback(() => {
        setActiveStep(prev => prev + 1);
    }, []);

    const handleBack = useCallback(() => {
        setActiveStep(prev => prev - 1);
    }, []);

    const handleStartReview = useCallback(() => {
        setReviewStarted(true);
        setActiveStep(0);
    }, []);

    const resetReview = useCallback(() => {
        setReviewStarted(false);
        setActiveStep(0);
        setReviewData(INITIAL_REVIEW_DATA);
        setCorrections({});
        setSubmitStatus({ success: false, error: null });
    }, []);

    const value = {
        reviewData,
        corrections,
        activeStep,
        reviewStarted,
        submitStatus,
        handleStepUpdate,
        handleCorrectionChange,
        handleNext,
        handleBack,
        handleStartReview,
        setSubmitStatus,
        resetReview
    };

    return (
        <ReviewContext.Provider value={value}>
            {children}
        </ReviewContext.Provider>
    );
};

export const useReview = () => {
    const context = useContext(ReviewContext);
    if (!context) {
        throw new Error('useReview must be used within a ReviewProvider');
    }
    return context;
}; 