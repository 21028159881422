import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTheme } from '@mui/material/styles';

export const DraggableVideo = ({ repost, videoPlayer, urlField, autoplay, isMuted, showDetails }) => {
    const videoRef = React.useRef(null);
    const containerRef = React.useRef(null);
    const [shouldLoad, setShouldLoad] = React.useState(false);
    const theme = useTheme();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: repost.id,
        data: {
            type: 'video',
            repost: repost
        }
    });

    // Add intersection observer to load video only when in viewport
    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShouldLoad(true);
                    observer.disconnect();
                }
            },
            {
                rootMargin: '50px', // Start loading slightly before the video comes into view
            }
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect();
    }, []);

    const memoizedVideoPlayer = React.useMemo(() => {
        if (!shouldLoad) return null;

        return React.cloneElement(videoPlayer, {
            ref: videoRef,
            src: repost[urlField],
            autoplay: autoplay,
            isDragging: isDragging,
            isMuted: isMuted,
            foundAt: repost.found_at,
            preload: "metadata", // Only load metadata initially
            style: {
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                visibility: isDragging ? 'visible' : undefined,
            }
        });
    }, [videoPlayer, repost[urlField], autoplay, isDragging, isMuted, shouldLoad]);

    React.useEffect(() => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const isPlaying = !videoRef.current.paused;

            if (isDragging) {
                videoRef.current.dataset.wasPlaying = isPlaying;
                if (isPlaying) videoRef.current.pause();
            } else {
                if (videoRef.current.dataset.wasPlaying === 'true') {
                    videoRef.current.play();
                }
            }

            videoRef.current.currentTime = currentTime;
        }
    }, [isDragging]);

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box
                ref={(el) => {
                    containerRef.current = el;
                    setNodeRef(el);
                }}
                style={{
                    transform: CSS.Transform.toString(transform),
                    transition: transition || 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                    opacity: isDragging ? 0.5 : 1,
                    cursor: isDragging ? 'grabbing' : 'grab',
                    touchAction: 'none',
                    zIndex: isDragging ? 1000 : 1,
                    position: 'relative',
                }}
                {...attributes}
                {...listeners}
                sx={{
                    aspectRatio: '1 / 1',
                    width: '100%',
                    maxWidth: '300px',
                    margin: '0 auto',
                    position: 'relative',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: 3
                    },
                    ...(isDragging && {
                        boxShadow: 8,
                    }),
                }}
            >
                {memoizedVideoPlayer}
                {!shouldLoad && (
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'action.hover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }} />
                )}
                {isDragging && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            zIndex: 1,
                            pointerEvents: 'none',
                        }}
                    />
                )}
                {showDetails && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.9)',
                            color: 'white',
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            zIndex: 2,
                        }}
                    >
                        <Typography variant="caption">
                            <strong>Service:</strong> {repost.service}
                        </Typography>
                        <Typography variant="caption">
                            <strong>Channel:</strong> {repost.channel}
                        </Typography>
                        <Typography variant="caption">
                            <strong>Date Uploaded:</strong> {new Date(repost.date_uploaded).toLocaleDateString()}
                        </Typography>
                        <Typography variant="caption">
                            <strong>Found At:</strong> {repost.found_at}
                        </Typography>
                        {repost.video_title &&
                            <Typography variant="caption">
                                <strong>Video Title:</strong> {repost.video_title}
                            </Typography>
                        }
                        <Typography variant="caption">
                            <strong>Use ID:</strong> {repost.id}
                        </Typography>

                    </Box>
                )}
            </Box>
        </Grid >
    );
};