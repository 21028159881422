import { Box, Typography, Avatar } from '@mui/material';
import { formatDateTime } from 'utils/dataUtils';

export const Notes = ({ notes }) => (
    <>
        <Typography
            variant="overline"
            sx={{
                letterSpacing: '0.1em',
                marginBottom: 1
            }}
        >
            NOTES
        </Typography>
        {notes.map((note, index) => (
            <Box
                key={index}
                sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 2,
                    padding: 2,
                    boxShadow: 1,
                    width: '100%',
                    '&:hover': {
                        boxShadow: 3,
                        transform: 'translateY(-2px)',
                        transition: 'all 0.2s ease-in-out'
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    width: '100%',
                }}>
                    <Avatar
                        src={note.user_photo}
                        alt={note.user}
                        sx={{
                            width: 40,
                            height: 40,
                            flexShrink: 0,
                            border: '2px solid',
                            borderColor: 'primary.light',
                        }}
                    >
                        {note.user.charAt(0)}
                    </Avatar>
                    <Box sx={{
                        flex: 1,
                        minWidth: 0,
                    }}>
                        <Box sx={{ mb: 0.5 }}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: 600,
                                    lineHeight: 1.2,
                                    display: 'inline-block',
                                    mr: 1,
                                    wordBreak: 'break-word'
                                }}
                            >
                                {note.user}
                            </Typography>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                                sx={{ display: 'inline-block' }}
                            >
                                {formatDateTime(note.date_created)}
                            </Typography>
                        </Box>
                        <Typography
                            variant="body2"
                            sx={{
                                whiteSpace: 'pre-wrap',
                                color: 'text.primary',
                                lineHeight: 1.6,
                                wordBreak: 'break-word'
                            }}
                        >
                            {note.note}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        ))}
    </>
);

const UserNotes = ({ notes, theme }) => {
    if (!notes?.length) return null;

    return (
        <Box sx={{
            position: 'absolute',
            right: "2.5%",
            top: '50%',
            transform: 'translateY(-50%)',
            width: '25%',
            minHeight: '60%',
            maxHeight: '90%',
            opacity: 0.9,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            zIndex: 1000,
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
            backdropFilter: 'blur(8px)',
            borderRadius: '16px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        }}>
            <Notes notes={notes} />
        </Box>
    );
};

export default UserNotes;