import { useMemo } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";

const useNavLinks = () => {
  const links = useMemo(
    () => [
      {
        to: "/guides",
        label: "Guides",
        openInNewTab: false,
        type: 'link'
      },
      {
        to: "/history",
        label: "History",
        openInNewTab: false,
        type: 'link'
      },
      {
        label: "More",
        type: 'dropdown',
        items: [
          {
            to: "/approvals",
            label: "Approvals",
            openInNewTab: false,
          }
        ]
      }
    ],
    []
  );

  return links;
};

export default useNavLinks;
