import { Box, Button, Autocomplete, TextField, FormControlLabel, Switch, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { alpha, useTheme } from '@mui/material/styles';

const AgreementTypeButtons = ({ value, onChange, theme }) => (
    <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: 2,
        width: '100%',
        maxWidth: '400px'
    }}>
        {['SETTLEMENT', 'TOLLING'].map((type) => (
            <Button
                key={type}
                variant={value === type ? "contained" : "outlined"}
                onClick={() => onChange(type)}
                sx={{
                    py: 2,
                    borderRadius: '12px',
                    transition: 'all 0.2s ease',
                    background: value === type
                        ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                        : 'transparent',
                    '&:hover': {
                        background: value === type
                            ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                            : alpha(theme.palette.background.default, 0.08),
                        transform: 'translateY(-2px)',
                        boxShadow: value === type
                            ? `0 6px 24px ${alpha(theme.palette.primary.main, 0.3)}`
                            : `0 4px 12px ${alpha(theme.palette.common.black, 0.05)}`,
                    }
                }}
            >
                {type} Agreement
            </Button>
        ))}
    </Box>
);

const ExpirationDateInput = ({ value, onChange, noExpiration, onNoExpirationChange, theme }) => (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControlLabel
            control={
                <Switch
                    checked={noExpiration}
                    onChange={(e) => {
                        onNoExpirationChange(e.target.checked);
                        if (e.target.checked) {
                            onChange(null);
                        }
                    }}
                />
            }
            label={
                <Typography
                    variant="body2"
                    sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 500
                    }}
                >
                    No Expiration Date
                </Typography>
            }
        />
        {!noExpiration && (
            <DatePicker
                value={value}
                onChange={(newValue) => {
                    if (newValue) {
                        const dateOnly = new Date(
                            newValue.getFullYear(),
                            newValue.getMonth(),
                            newValue.getDate()
                        );
                        onChange(dateOnly);
                    } else {
                        onChange(null);
                    }
                }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        size: "small"
                    }
                }}
            />
        )}
    </Box>
);

const CorrectionInput = ({
    type,
    value,
    onChange,
    matterOptions,
    onSave,
    noExpiration,
    onNoExpirationChange
}) => {
    const theme = useTheme();

    const renderInput = () => {
        switch (type) {
            case 'auto_switch':
                return (
                    <AgreementTypeButtons
                        value={value}
                        onChange={onChange}
                        theme={theme}
                    />
                );

            case 'matter':
                return (
                    <Autocomplete
                        options={matterOptions}
                        getOptionLabel={(option) => option?.name || ''}
                        value={value}
                        onChange={(_, newValue) => onChange(newValue)}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                placeholder="Search for matter"
                                size="small"
                            />
                        )}
                    />
                );

            case 'date':
                return (
                    <DatePicker
                        value={value}
                        onChange={(newValue) => {
                            if (newValue) {
                                const dateOnly = new Date(
                                    newValue.getFullYear(),
                                    newValue.getMonth(),
                                    newValue.getDate()
                                );
                                onChange(dateOnly);
                            } else {
                                onChange(null);
                            }
                        }}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                size: "small"
                            }
                        }}
                    />
                );

            case 'expiration_date':
                return (
                    <ExpirationDateInput
                        value={value}
                        onChange={onChange}
                        noExpiration={noExpiration}
                        onNoExpirationChange={onNoExpirationChange}
                        theme={theme}
                    />
                );

            default:
                return null;
        }
    };

    const isValid = () => {
        if (type === 'expiration_date') {
            return noExpiration || value;
        }
        return value !== null && value !== undefined;
    };

    return (
        <Box sx={{
            mt: 4,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            alignItems: 'center'
        }}>
            <Box sx={{ width: '100%', maxWidth: 400 }}>
                {renderInput()}
            </Box>

            <Button
                variant="contained"
                onClick={onSave}
                disabled={!isValid()}
                sx={{
                    mt: 2,
                    px: 4,
                    py: 1,
                    borderRadius: '12px',
                    background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                    '&:hover': {
                        background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                        transform: 'translateY(-2px)',
                        boxShadow: `0 6px 24px ${alpha(theme.palette.primary.main, 0.3)}`
                    }
                }}
            >
                Save & Continue
            </Button>
        </Box>
    );
};

export default CorrectionInput; 