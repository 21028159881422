import Fiber from "@mui/icons-material/FiberSmartRecord";
import MobileDataOff from "@mui/icons-material/MobiledataOff";
import TableRows from "@mui/icons-material/TableRows";
import PriceChangeIcon from "@mui/icons-material/PriceChange";

export const DEFAULT_COLUMNS = [
    'song_title',
    'artist',
    'ownership_info',
    'side',
    'service',
    'channel',
    'video_title',
    'found_at',
    'use_duration',
    "online",
    "date_checked",
    'date_uploaded',
    'page_url',
    'archive_link',
    'id'
];

export const REPOST_COLUMNS = [
    "rp_reviewed",
    'similarity',
    'song_title',
    'artist',
    'side',
    'repost',
    'repost_group',
    'id'
];

export const PRICING_COLUMNS = [
    "priced", "video_title", "service", "date_uploaded", "song_title", "flag", "use_duration", "quality", "popularity_rank", "valuation", "id"
];

export const VIEW_MODES = {
    USES: 'uses',
    REPOST: 'repost',
    PRICING: 'pricing',
    UPDOWN: 'updown'
};

export const EXPORT_NAMES = {
    [VIEW_MODES.USES]: "UTBP",
    [VIEW_MODES.REPOST]: "Reposts",
    [VIEW_MODES.PRICING]: "Pricing",
    [VIEW_MODES.UPDOWN]: "UpDown"
};

export const BUTTON_CONFIG = [
    {
        id: VIEW_MODES.USES,
        label: 'Usage Report',
        icon: TableRows,
        borderColor: "#000000"
    },
    {
        id: VIEW_MODES.REPOST,
        label: 'Repost Review',
        icon: Fiber,
        borderColor: '#FF5722'
    },
    {
        id: VIEW_MODES.PRICING,
        label: 'Pricing',
        icon: PriceChangeIcon,
        borderColor: '#4caf50'
    },
    {
        id: VIEW_MODES.UPDOWN,
        label: 'Up Down',
        icon: MobileDataOff,
        borderColor: "#000000"
    }
];
