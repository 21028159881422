import React from 'react';
import FollowupDetail from './Views/FollowupDetail';
import AgreementReviewDetail from './Views/AgreementReview/AgreementReview';
import TollsExpiringDetail from './Views/TollsExpiringDetail';
import PartnerPricingReviewDetail from './Views/PartnerPricingReviewDetail';

// Registry of detail view components by type
const DETAIL_VIEW_REGISTRY = {
    agreement_review: AgreementReviewDetail,
    followup: FollowupDetail,
    toll_expiring: TollsExpiringDetail,
    partner_pricing_review: PartnerPricingReviewDetail,
    // Default view for unhandled types
    default: ({ item }) => (
        <div>Default View for type: {item.type}</div>
    )
};

export const getDetailComponent = (type) => {
    return DETAIL_VIEW_REGISTRY[type] || DETAIL_VIEW_REGISTRY.default;
};