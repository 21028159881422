import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(4),
    background: `linear-gradient(145deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: theme.shadows[1]
}));

export const GradientTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    marginBottom: theme.spacing(1)
}));

export const buttonStyles = (theme) => ({
    borderRadius: '12px',
    py: 1.5,
    px: 4,
    textTransform: 'none',
    fontSize: '1.1rem',
    background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    boxShadow: `0 4px 20px ${theme.palette.primary.main}25`,
    '&:hover': {
        background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        transform: 'translateY(-2px)',
        boxShadow: `0 6px 24px ${theme.palette.primary.main}30`
    }
});

export const cardStyles = (theme) => ({
    mb: 2,
    backgroundColor: 'background.paper',
    border: '1px solid',
    borderColor: 'divider',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateX(4px)'
    }
});

export const stepperStyles = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    '&:last-child': { pb: 4 }
}; 