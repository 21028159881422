import { useState, useEffect, useCallback } from "react";
import { DataTableGrouped } from "./common/DataTable";
import { Typography, Box, Modal, Card, CardContent, Grid } from "@mui/material";
import DragAndDrop from 'components/common/DragAndDrop/DragAndDrop';
import { useMatterFiles } from 'services/api';
import ErrorBoundary from './common/ErrorBoundary';
import { titleAndReplace, formatDateTimeWithWeekday, formatDateTime } from 'utils/dataUtils';


const formatUrl = (driveId) => {
  return `https://drive.google.com/file/d/${driveId}`;
};

const parseFiles = (data) => {
  return data
    .map((item) => {
      // const dateObject = new Date(item.date);
      const emailDateObject = item.email_date ? new Date(item.email_date) : null;
      const dateModifiedObject = item.date_modified ? new Date(item.date_modified) : null;

      return {
        ...item,
        email_date: emailDateObject ? formatDateTime(emailDateObject) : null,
        date_modified: dateModifiedObject ? formatDateTime(dateModifiedObject) : null,
        url: formatUrl(item.drive_id)
      };
    })
    .sort((a, b) => {
      const sortableCategories = ['TOLLING', 'SETTLEMENT'];
      if (sortableCategories.includes(a.category) && sortableCategories.includes(b.category)) {
        // Sort by date (most recent first)
        const dateComparison = b.dateObject - a.dateObject;
        if (dateComparison !== 0) return dateComparison;

        // If dates are equal, sort by status alphabetically
        return a.status.localeCompare(b.status);
      }
      // For other categories, maintain original order
      return 0;
    })
    .map(({ dateObject, ...item }) => item);
};


const groupColumnConfigs = {
  'TOLLING': ['email_date', 'category', 'status', 'filename', 'effective_date', 'expiration_date', 'days_remaining', 'no_expiration'],
  'SETTLEMENT': ['email_date', 'category', 'status', 'filename', 'effective_date'],
  'FINANCIAL': ['email_date', 'category', 'filename'],
  'SIGNATURE_PAGE': ['email_date', 'category', 'filename'],
  'COMPOSER_AGREEMENT': ['category', 'filename'],
  'USAGE_REPORT': ['email_date', 'category', 'filename'],
  'MISC': ['email_date', 'category', 'filename'],
  'COURT': ['email_date', 'category', 'filename'],
  'LICENSE': ['email_date', 'category', 'filename'],
  'LETTER': ['email_date', 'category', 'filename'],
  'EXHIBIT': ['email_date', 'category', 'filename'],
  'EVIDENCE_REPORT': ['email_date', 'category', 'filename'],
  'REGISTRATION': ['email_date', 'category', 'filename'],

};

const DoNotDisplayCols = ['id', 'file_hash', 'matter_id', 'drive_id', 'url', 'page_url'];
// Add this new component for document details
export const DocumentDetails = ({ document, open, onClose }) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="document-details-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 800,
        width: '90%',
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        p: 4,
        maxHeight: '90vh',
        overflow: 'auto'
      }}>
        <Typography
          variant="h4"
          sx={{
            mb: 4,
            fontWeight: 500,
            color: 'text.primary',
            borderBottom: '2px solid',
            borderColor: 'primary.main',
            pb: 1
          }}
        >
          Document Details
        </Typography>

        {Object.entries(document)
          .filter(([key, value]) => value !== null && value !== undefined && !DoNotDisplayCols.includes(key))
          .map(([key, value]) => (
            <Card
              key={key}
              elevation={0}
              sx={{
                mb: 2,
                backgroundColor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      sx={{ textTransform: 'uppercase', letterSpacing: 0.5 }}
                    >
                      {titleAndReplace(key)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography>{value || '-'}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        <Box sx={{ mt: 2, textAlign: 'center', display: 'flex', gap: 2, justifyContent: 'center' }}>
          {document.drive_id && (
            <Typography
              component="a"
              href={document.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Open Document in Drive
            </Typography>
          )}
          {document.page_url && (
            <Typography
              component="a"
              href={document.page_url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' }
              }}
            >
              Open Email
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const Files = ({ matter }) => {
  const [data, setData] = useState([]);
  const matterId = matter.id;
  const matterName = matter.name;
  const [selectedDocument, setSelectedDocument] = useState(null);

  // Replace the axios call with React Query hook
  const { data: file_data, isLoading } = useMatterFiles(matterId);

  // Update second useEffect to watch the query data
  useEffect(() => {
    if (file_data && file_data.length > 0) {
      setData(parseFiles(file_data));
    }
  }, [file_data]);

  const onRowClick = useCallback((rowData) => {
    setSelectedDocument(rowData.row);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedDocument(null);
  }, []);

  if (isLoading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (!file_data || file_data.length === 0) {
    return (
      <>
        <Typography variant="h6">No file data found.</Typography>
        <Box sx={{ mt: 2 }}>
          <DragAndDrop matterId={matterId} matterName={matterName} />
        </Box>
      </>
    );
  }

  return (
    <ErrorBoundary>
      <DataTableGrouped
        data={data}
        onRowClick={onRowClick}
        columns={groupColumnConfigs}
        groupBy="category"
        itemName="file"
      />
      {selectedDocument && (
        <DocumentDetails
          document={selectedDocument}
          open={Boolean(selectedDocument)}
          onClose={handleCloseModal}
        />
      )}
      <Box sx={{ mt: 2 }}>
        <DragAndDrop matterId={matterId} matterName={matterName} />
      </Box>
    </ErrorBoundary>
  );
};

export default Files;
