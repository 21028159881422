import { format } from "date-fns";

export const formatMoney = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatDate = (dateString) => {
  return dateString ? format(new Date(dateString), "MMM d, yyyy") : "N/A";
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(navigator.language || 'en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }).replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2');
};

export const formatDateTimeWithWeekday = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString(navigator.language || 'en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }).replace(/(\d+)\/(\d+)\/(\d+),/, '$3-$1-$2');
};

export const formatDateWithWeekday = (dateValue) => {
  if (!dateValue) return '';

  return new Date(dateValue).toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  }).replace(/(\d+)(?=\D*$)/, num => {
    const n = parseInt(num);
    return n + (['th', 'st', 'nd', 'rd'][n % 10 > 3 || (n % 100 - n % 10 === 10) ? 0 : n % 10]);
  });
};

export const formatPercentage = (value) => {
  if (value === null || value === undefined) {
    return "N/A";
  }
  const percentage = (value * 100).toFixed(2);
  return `${percentage}%`;
};

// Function to format column names
export function camelToNormal(str) {
  // Check if string has exactly one capital letter after the first character
  if (str.slice(1).match(/^[^A-Z]*[A-Z][^A-Z]*$/)) {
    return str.replace(/([A-Z])/g, ' $1').trim();
  }
  return str;
}

export function titleCase(str) {
  // First convert from camel case if needed
  const normalized = camelToNormal(str);
  return normalized
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function titleAndReplace(col) {
  const specialCases = {
    id: "ID",
    cot: "COT",
    iswc: "ISWC",
    isrc: "ISRC",
    mlc: "MLC",
    url: "URL"
  };

  // Replace underscores with spaces
  let formattedCol = col.replace(/_/g, " ");

  // Check for special cases
  for (const [key, value] of Object.entries(specialCases)) {
    const regex = new RegExp(`\\b${key}\\b`, 'gi');
    formattedCol = formattedCol.replace(regex, value);
  }

  // Apply case transformations
  if (col.includes("_id") && col.length < 7) {
    formattedCol = formattedCol.toUpperCase();
  } else if (col.length > 3) {
    formattedCol = titleCase(formattedCol);
  } else {
    formattedCol = formattedCol.toUpperCase();
  }

  return formattedCol;
}

// Calculate the number of seconds between two timestamps
export const calculateDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const durationMs = end - start;
  const seconds = Math.floor(durationMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    return `${hours}h ${minutes % 60}m ${seconds % 60}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds % 60}s`;
  } else {
    return `${seconds}s`;
  }
};


export const snake_case = (text) => {
  try {
    text = text.toLowerCase();
    text = text.replace(" ", "_").replace("-", "_").replace("/", "_").replace("'", "");
  } catch {
    // Handle any errors that might occur during the process
  }
  return text;
};
