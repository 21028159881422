// src/services/api.js
import axios from "axios";
import { useMutation, useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

export const API_BASE_URL = process.env.NODE_ENV === 'production' ? '/api' : "http://localhost:8000/api"

console.log("API_BASE_URL", API_BASE_URL);

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  decompress: true,
});


// Request interceptor for adding token
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const authService = {
  login: (username, password) =>
    api.post(
      "/auth/token",
      `username=${encodeURIComponent(username)}&password=${encodeURIComponent(
        password
      )}`,
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    ),
  logout: () => api.post("/auth/logout"),
  getCurrentUser: () => api.get("/auth/users/me"),
  getUserProfile: () => api.get("/auth/users/profile"),
  requestPasswordReset: async (email) => {
    return await api.post('/auth/forgot-password', { email });
  },
  verifyResetToken: (token) => api.post("/auth/verify-reset-token", null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }),
  updatePassword: (token, newPassword) => api.post("/auth/update-password", { token, new_password: newPassword }),
};

export const statusService = {
  checkStatus: async () => {
    try {
      const response = await api.get('/health-check');
      console.log('API health check response:', response);
      return response.status === 200;
    } catch (error) {
      console.error('API health check failed:', error);
      return false;
    }
  },
};

export const songService = {
  getRecentSongs: () => api.get("/recent-songs"),
  // Add other song-related API calls here as needed
};

export const accountService = {
  getAccountDetails: (accountId) => api.get(`/account/${accountId}`),
  getAssociatedMatters: (accountId) =>
    api.get(`/account_related_matters/${accountId}`),
};

export const songTitleService = {
  getSongTitle: (songTitleId) => api.get(`/song_title/${songTitleId}`),
  getSongTitleTable: (songTitleId) =>
    api.get(`/song_title_table/${songTitleId}`),
};

export const licenseService = {
  getLicenseDetails: (licenseId) => api.get(`/license_detail/${licenseId}`),
  // Add other license-related API calls here as needed
};

export const guideService = {
  getGuides: () => api.get("/guides"),
  // Add other guide-related API calls here as needed
};

export const useService = {
  getUses: (matterId) => api.get(`/uses/${matterId}`),
  getUseDetails: (useId) => api.get(`/use/${useId}`),
  // Add other use-related API calls here as needed
};

export const searchService = {
  search: (category, term) => {
    let endpoint;
    switch (category) {
      case "matters":
        endpoint = "/search/matters";
        break;
      case "songs":
        endpoint = "/search/songs";
        break;
      case "licenses":
        endpoint = "/search/licenses";
        break;
      case "payments":
        endpoint = "/search/payments";
        break;
      default:
        throw new Error("Invalid search category");
    }
    return api.get(`${endpoint}?q=${encodeURIComponent(term)}`);
  },
};

export const matterService = {
  getMatterDetails: (opportunityId) =>
    api.get(`/matter_detail/${opportunityId}`),
  getAllMatters: () => api.get("/all_matters"),
};

export const attachmentService = {
  getAttachments: () => api.get("/attachments"),
};

export const pricingService = {
  getNotes: async (useId) => await api.get(`pricing/notes/${useId}`),
  videoQuality: async (videoId, quality) => await api.put(`pricing/video_quality`, { video_id: videoId, quality }),
  popularityRank: async (id, rank) => await api.put(`pricing/popularity_rank`, { id, popularity_rank: rank }),
  override: async (useId, override) => await api.put(`pricing/override`, { id: useId, override }),
  addNote: async (matterId, useId, note) => await api.post(`pricing/note`, { opportunity_id: matterId, use_id: useId, note }),
  addFlag: async (useId, flag) => await api.put(`pricing/flag`, { id: useId, flag }),
  getLatestUseValuation: async (useId) => await api.get(`pricing/latest_use_valuation/${useId}`),
  addMatterReview: async (matterReview) => await api.post(`pricing/matter_review`, matterReview),
  getMatterReview: async (matterId) => await api.get(`pricing/matter_review/${matterId}`),
  getMatterNotes: async (matterId) => await api.get(`pricing/matter_notes/${matterId}`),
  updateUse: async (useId, updateField, currentValues) => {
    return await api.put('pricing/update_use', {
      use_id: useId,
      update_field: updateField,
      current_values: currentValues
    });
  },
};

export const approvalService = {
  getClients: async () => await api.get('/approvals/clients'),
  getApprovals: async (clientId) => await api.get(`approvals/approvals/${clientId}`),
  getDetections: async (clientId, accountId) => await api.get(`approvals/detections/${clientId}/${accountId}`),
  updateAccountStatus: async (data) => await api.put('/approvals/update_account_status', data),
  importData: async (data) => await api.post('/approvals/import', data),
};

export const metricsService = {
  getMetrics: async () => await api.get('/metrics'),
};

export const repostService = {
  getReposts: async (matterId) => await api.get(`reposts/reposts/${matterId}`),
  updateRepostGroup: async (repost) =>
    await api.put('reposts/reposts', repost),
  updateRepostsReviewed: async (reposts) =>
    await api.put('reposts/reposts_reviewed', reposts),
  getMatterReviews: async (matterId) => await api.get(`reposts/matter_reviews/${matterId}`),
  addMatterReview: async (matterReview) => await api.post('reposts/matter_reviews', matterReview),
  getMatterReviewsAuto: async (matterId) => await api.get(`reposts/matter_reviews_auto/${matterId}`),
};

export const userHistoryService = {
  getUserHistory: async () => await api.get('/task_history'),
};

export const logService = {
  getLogs: async () => await api.get('/get_logs')
};

export const firmService = {
  getFirm: async (firmId) => await api.get(`/firm/${firmId}`),
};

export const userSuggestionService = {
  suggest: async (userSuggestion) => {
    // userSuggestion should have: suggestion_type, suggestion, and page_url
    return await api.post('/user_suggestions', userSuggestion);
  },
};

// / Raw API functions
export const fileApi = {
  uploadMatterFile: async (metadata, file) => {
    const formData = new FormData();
    formData.append('request', JSON.stringify(metadata));
    formData.append('file', file, file.name);

    const response = await api.post('/upload_matter_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });
    return response.data;
  },
  getMatterFiles: async (matterId) => {
    const response = await api.get(`/matter_files/${matterId}`);
    return response.data;
  },
};

// React Query hooks
export const useUploadMatterFile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ metadata, file }) => fileApi.uploadMatterFile(metadata, file),
    onSuccess: () => {
      queryClient.invalidateQueries(['matterFiles']);
    },
  });
};

export const useMatterFiles = (matterId) => {
  return useQuery({
    queryKey: ['matterFiles', matterId],
    queryFn: () => fileApi.getMatterFiles(matterId),
    // Optional: Add configuration
    enabled: !!matterId, // Only run query if matterId exists
  });
};

// You can keep the old service for backward compatibility during migration
export const fileService = {
  uploadMatterFile: fileApi.uploadMatterFile,
  getMatterFiles: fileApi.getMatterFiles,

};

// Raw API functions
export const dashboardApi = {
  getDashboardFeed: async () => {
    const response = await api.get('dashboard/dashboard_feed');
    return response.data;
  },
  getAgreement: async (id) => {
    const response = await api.get(`dashboard/agreement/${id}`);
    return response.data;
  },
  submitAgreementReview: async (id, reviewData) => {
    const response = await api.post(`dashboard/submit_agreement_review/${id}`, reviewData);
    return response.data;
  },
  completeTask: async (id) => {
    const response = await api.post(`dashboard/complete_task/${id}`);
    return response.data;
  },
};

// React Query hooks
export const useDashboardFeed = () => {
  return useQuery({
    queryKey: ['dashboardFeed'],
    queryFn: () => dashboardApi.getDashboardFeed(),
  });
};

export const useAgreement = (id) => {
  return useQuery({
    queryKey: ['agreement', id],
    queryFn: () => dashboardApi.getAgreement(id),
    enabled: !!id,
  });
};

export const useSubmitAgreementReview = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, reviewData }) => dashboardApi.submitAgreementReview(id, reviewData),
    onSuccess: () => {
      queryClient.invalidateQueries(['dashboardFeed']);
      queryClient.invalidateQueries(['agreement']);
    },
  });
};

export const useCompleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => dashboardApi.completeTask(id),
    onError: (err, id) => {
      console.error('Failed to complete task:', err);
    },
    onSettled: () => {
    },
  });
};

// Keep the old service for backward compatibility during migration
export const dashboardService = dashboardApi;

export default api;



