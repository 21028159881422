import { Autocomplete, Box, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Typography, Button } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { TextBox } from '../../utils/TextBox';
import ViewAgreementButton from '../../utils/ViewAgreementButton';
import CorrectionInput from './corrections/CorrectionInput';
import { StyledBox, GradientTypography } from './styles';

const ReviewStep = memo(({
    title,
    subtitle,
    noValueTitle,
    noValueSubtitle,
    value,
    options,
    onChange,
    text,
    driveUrl,
    hasOriginalValue,
    correctionType,
    correctionValue,
    onCorrectionChange,
    matterOptions,
    category,
    noExpiration,
    onNoExpirationChange
}) => {
    const theme = useTheme();

    const displayTitle = hasOriginalValue ? title : noValueTitle;
    const displaySubtitle = hasOriginalValue ? subtitle : noValueSubtitle;

    // Only show correction UI directly if there's no original value
    const showCorrectionDirectly = !hasOriginalValue;
    // Show correction UI if user selected 'no' or if there was no original value
    const showCorrection = value === 'no' || showCorrectionDirectly;

    const handleOptionSelect = (selectedValue) => {
        onChange(selectedValue);

        // For agreement type, automatically set the correction when 'no' is selected
        if (selectedValue === 'no' && correctionType === 'auto_switch') {
            const newType = category === 'SETTLEMENT' ? 'TOLLING' : 'SETTLEMENT';
            onCorrectionChange(newType);
        }
    };

    const handleSaveCorrection = () => {
        if (correctionType === 'expiration_date' && (noExpiration || correctionValue)) {
            onChange(value || 'no', true);
        } else if (correctionValue) {
            onChange(value || 'no', true);
        }
    };

    const AgreementTypeButtons = () => (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
            width: '100%',
            maxWidth: '400px'
        }}>
            {['SETTLEMENT', 'TOLLING'].map((type) => (
                <Button
                    key={type}
                    variant={correctionValue === type ? "contained" : "outlined"}
                    onClick={() => onCorrectionChange(type)}
                    sx={{
                        py: 2,
                        borderRadius: '12px',
                        transition: 'all 0.2s ease',
                        background: correctionValue === type
                            ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                            : 'transparent',
                        '&:hover': {
                            background: correctionValue === type
                                ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                                : alpha(theme.palette.background.default, 0.08),
                            transform: 'translateY(-2px)',
                            boxShadow: correctionValue === type
                                ? `0 6px 24px ${alpha(theme.palette.primary.main, 0.3)}`
                                : `0 4px 12px ${alpha(theme.palette.common.black, 0.05)}`,
                        }
                    }}
                >
                    {type} Agreement
                </Button>
            ))}
        </Box>
    );

    return (
        <StyledBox>
            <Box sx={{ mb: 4, textAlign: 'left', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                    <GradientTypography variant="h5">
                        {displayTitle}
                    </GradientTypography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: alpha(theme.palette.text.primary, 0.7),
                            lineHeight: 1.5
                        }}
                    >
                        {displaySubtitle}
                    </Typography>
                </Box>
                {driveUrl && <ViewAgreementButton url={driveUrl} />}
            </Box>

            {text && <TextBox text={text} />}

            {!showCorrection && (
                <FormControl required fullWidth>
                    <RadioGroup
                        value={value}
                        onChange={(e) => handleOptionSelect(e.target.value)}
                        row
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gap: 2,
                            width: '100%',
                            maxWidth: '400px',
                            mx: 'auto'
                        }}
                    >
                        {options.map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio sx={{ display: 'none' }} />}
                                label={
                                    <Box
                                        sx={{
                                            width: '100%',
                                            py: 2,
                                            px: 4,
                                            textAlign: 'center',
                                            borderRadius: '12px',
                                            cursor: 'pointer',
                                            transition: 'all 0.2s ease',
                                            background: value === option.value
                                                ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                                                : alpha(theme.palette.background.default, 0.03),
                                            border: `1px solid ${value === option.value
                                                ? theme.palette.primary.main
                                                : theme.palette.divider}`,
                                            boxShadow: value === option.value
                                                ? `0 4px 20px ${alpha(theme.palette.primary.main, 0.25)}`
                                                : 'none',
                                            '&:hover': {
                                                background: value === option.value
                                                    ? `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`
                                                    : alpha(theme.palette.background.default, 0.08),
                                                transform: 'translateY(-2px)',
                                                boxShadow: value === option.value
                                                    ? `0 6px 24px ${alpha(theme.palette.primary.main, 0.3)}`
                                                    : `0 4px 12px ${alpha(theme.palette.common.black, 0.05)}`,
                                            }
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: 500,
                                                color: value === option.value
                                                    ? theme.palette.primary.contrastText
                                                    : theme.palette.text.primary
                                            }}
                                        >
                                            {option.label}
                                        </Typography>
                                    </Box>
                                }
                                sx={{ margin: 0, width: '100%' }}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            )}

            {showCorrection && (
                <CorrectionInput
                    type={correctionType}
                    value={correctionValue}
                    onChange={onCorrectionChange}
                    matterOptions={matterOptions}
                    onSave={handleSaveCorrection}
                    noExpiration={noExpiration}
                    onNoExpirationChange={onNoExpirationChange}
                />
            )}
        </StyledBox>
    );
});

ReviewStep.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    noValueTitle: PropTypes.string.isRequired,
    noValueSubtitle: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    driveUrl: PropTypes.string,
    hasOriginalValue: PropTypes.bool.isRequired,
    correctionType: PropTypes.oneOf(['text', 'boolean', 'date', 'select', 'matter', 'auto_switch', 'expiration_date']),
    correctionValue: PropTypes.any,
    onCorrectionChange: PropTypes.func,
    matterOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    category: PropTypes.string,
    noExpiration: PropTypes.bool,
    onNoExpirationChange: PropTypes.func
};

ReviewStep.displayName = 'ReviewStep';

export default ReviewStep;