import React, { useState } from 'react';
import { Button, ButtonGroup, Menu, MenuItem, CircularProgress } from "@mui/material";
import { KeyboardArrowDown } from '@mui/icons-material';
import { BUTTON_CONFIG, VIEW_MODES } from './constants';
import PropTypes from 'prop-types';

const UseActionButtons = ({ currentView, onViewChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const buttonStyle = (buttonId) => ({
        color: currentView === buttonId ? "primary.dark" : "text.primary",
        borderColor: "transparent",
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            color: 'primary.light',
        },
        px: 2,
        py: 0.75,
        fontSize: '0.875rem',
    });

    const buttonGroupStyle = {
        '& .MuiButtonGroup-grouped:not(:last-of-type)': {
            borderColor: 'transparent',
        },
        '& .MuiButton-startIcon': {
            marginRight: 1,
        },
    };

    return (
        <>
            <ButtonGroup
                variant="text"
                aria-label="Matter actions"
                size="medium"
                sx={buttonGroupStyle}
            >
                {BUTTON_CONFIG.filter(button => button.id !== 'updown').map(({ id, label, icon: Icon }) => (
                    <Button
                        key={id}
                        onClick={() => onViewChange(id)}
                        startIcon={<Icon />}
                        sx={buttonStyle(id)}
                    >
                        {label}
                    </Button>
                ))}
                <Button
                    aria-controls={open ? 'actions-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={buttonStyle('actions')}
                    endIcon={isLoading ? <CircularProgress size={20} /> : <KeyboardArrowDown />}
                >
                    Actions
                </Button>
            </ButtonGroup>
            <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {BUTTON_CONFIG.filter(button => button.id === 'updown').map(({ id, label, icon: Icon }) => (
                    <MenuItem
                        key={id}
                        onClick={() => {
                            setIsLoading(true);
                            onViewChange(id);
                            handleClose();
                        }}
                    >
                        <Icon sx={{ mr: 1 }} />
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

UseActionButtons.propTypes = {
    currentView: PropTypes.oneOf(Object.values(VIEW_MODES)).isRequired,
    onViewChange: PropTypes.func.isRequired,
};

export default UseActionButtons; 