// SettlementAgreementFields.js
import React, { useState } from 'react';
import {
    Typography,
    Box,
} from '@mui/material';
import { DateField, ReleaseTypeField, ExecutionStatusField, PaymentDueField } from './FormFields';

const SettlementAgreementFields = ({ onDataChange, errors }) => {
    const [formData, setFormData] = useState({
        executionStatus: '',
        releaseType: 'Limited',
        // ... other fields
    });

    const handleChange = (field) => (event) => {
        const newFormData = {
            ...formData,
            [field]: event.target.value
        };
        setFormData(newFormData);
        onDataChange(newFormData);
    };

    return (
        <Box mt={2}>
            <Typography variant="h6" gutterBottom>
                Settlement Agreement Fields
            </Typography>
            <DateField
                label="Effective Date"
                value={formData.effectiveDate}
                onChange={(e) => setFormData({ ...formData, effectiveDate: e.target.value })}
                error={!!errors.effectiveDate}
                helperText={errors.effectiveDate}
                required={true}
            />
            <PaymentDueField
                label="Payment Due"
                value={formData.paymentDue}
                onChange={(e) => setFormData({ ...formData, paymentDue: e.target.value })}
                error={!!errors.paymentDue}
                helperText={errors.paymentDue}
                required={true}
            />
            <ReleaseTypeField
                label="Release Type"
                value={formData.releaseType}
                onChange={handleChange('releaseType')}

            />
            <ExecutionStatusField
                value={formData.executionStatus}
                onChange={handleChange('executionStatus')}
                error={!!errors.executionStatus}
                helperText={errors.executionStatus}
                required={true}
            />
        </Box>
    );
};

export default SettlementAgreementFields;