import { Box, CircularProgress, Typography, Paper } from "@mui/material";
import PropTypes from 'prop-types';
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useNotifications } from 'components/common/Notifications/NotificationContext';
import { useAuth } from 'contexts/AuthContext';

import useTaskManagement from 'pages/Tasks/useTaskManagement';
import RepostsUse from 'pages/Reposts/RepostsUse';
import PricingUse from 'pages/Pricing/PricingUse';

import { UsesTable } from './components/UsesTable';
import { VIEW_MODES, EXPORT_NAMES } from './constants';
import UseActionButtons from './UseActionButtons';
import { useUsesData } from './hooks/useUsesData';
import { useSelectedUse } from './hooks/useSelectedUse';
import { useViewManagement } from './hooks/useViewManagement';
import { useRepostManagement } from './hooks/useRepostManagement';
import { usePricingManagement } from './hooks/usePricingManagement';

export const LoadingSpinner = () => (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%", pt: 4 }}>
    <CircularProgress />
  </Box>
);

export const ErrorMessage = ({ error }) => (
  <Box sx={{ height: "100%", width: "100%", pt: 4 }}>
    <Typography variant="h6" color="error">
      Error: {error}
    </Typography>
  </Box>
);


const makeFilename = (matterName, currentView) => {
  const currentDate = new Date().toISOString().split('T')[0];
  const exportName = EXPORT_NAMES[currentView] || 'Export';
  return `${matterName} - ${exportName} - ${currentDate}.xlsx`;
};

const Uses = ({ matter, scrollToTabs }) => {
  const { id: matterId } = matter;
  const { uses, setUses, isLoading, error } = useUsesData(matterId);
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const { createTask } = useTaskManagement();
  const { currentUser } = useAuth();

  // Shared selected use state
  const { selectedUseId, setSelectedUseId, clearSelectedUse } = useSelectedUse();

  const {
    currentView,
    getTableColumns,
    getTableStyle,
    handleViewChange,
    shouldShowInfoSection,
  } = useViewManagement({ matterId, createTask, addNotification, scrollToTabs });

  const {
    repostUses,
    isRepostsOpen,
    setIsRepostsOpen,
    handleUpdateRepostGroup,
    handleCloseReposts,
    getRepostInfoContent,
    handleUpdateRepostsReviewed,
  } = useRepostManagement({
    matter,
    uses,
    setUses,
    matterId,
    currentUser,
    selectedUseId,
    clearSelectedUse,
    initialTab: 'Logs',
  });

  const {
    getPricingInfoContent,
    isPricingOpen,
    setIsPricingOpen,
    orderedUseIds,
    handlePricingClose,
    handleOrderChanged,
    initializeOrderedIds,
    handleUpdates,
  } = usePricingManagement({
    matter,
    selectedUseId,
    uses,
    setUses,
    clearSelectedUse,
    currentUser,
  });

  const initializeOrderedIdsEffect = useCallback(() => {
    if (uses.length > 0) {
      initializeOrderedIds(uses);
    }
  }, [uses, initializeOrderedIds]);

  useEffect(() => {
    initializeOrderedIdsEffect();
  }, [initializeOrderedIdsEffect]);

  const handleRowClick = useCallback((row) => {
    switch (currentView) {
      case VIEW_MODES.REPOST:
        setSelectedUseId(row.id);
        setIsRepostsOpen(true);
        break;
      case VIEW_MODES.PRICING:
        setSelectedUseId(row.id);
        setIsPricingOpen(true);
        break;
      default:
        navigate(`/use/${row.id}`, { state: { useId: row.id } });
    }
  }, [navigate, currentView, setSelectedUseId, setIsRepostsOpen, setIsPricingOpen]);

  const getInfoContent = useCallback(() => {
    switch (currentView) {
      case VIEW_MODES.REPOST:
        return getRepostInfoContent();
      case VIEW_MODES.PRICING:
        return getPricingInfoContent();
      default:
        return null;
    }
  }, [currentView, getRepostInfoContent, getPricingInfoContent]);

  const getExportFilename = useCallback(() => {
    return makeFilename(matter.name, currentView);
  }, [matter.name, currentView]);

  if (isLoading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  return (

    <Box sx={{
      flexGrow: 1,
      overflow: "auto",
      width: "100%",
      height: "calc(100vh - 64px)",
      position: "relative",
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <UseActionButtons
          currentView={currentView}
          onViewChange={handleViewChange}
          scrollToTabs={scrollToTabs}
        />
      </Box>

      {shouldShowInfoSection() && (
        <Paper
          elevation={2}
          sx={{
            p: 2,
            m: 2,
            backgroundColor: 'background.paper',
            minHeight: '100px'
          }}
        >
          {getInfoContent()}
        </Paper>
      )}

      <UsesTable
        key={currentView}
        data={currentView === VIEW_MODES.REPOST ? repostUses : uses}
        defaultVisibleColumns={getTableColumns()}
        onRowClick={handleRowClick}
        tableStyle={getTableStyle()}
        trackOrderBy={currentView === VIEW_MODES.PRICING ? "id" : undefined}
        onOrderChanged={currentView === VIEW_MODES.PRICING ? handleOrderChanged : undefined}
        pagination={false}
        exportFilename={getExportFilename()}
      />

      {isRepostsOpen && (
        <RepostsUse
          isOpen={isRepostsOpen}
          onClose={handleCloseReposts}
          repostData={uses}
          selectedUseId={selectedUseId}
          onUpdateRepostGroup={handleUpdateRepostGroup}
          onUpdateRepostsReviewed={handleUpdateRepostsReviewed}
        />
      )}

      {isPricingOpen && (
        <PricingUse
          isOpen={isPricingOpen}
          onClose={handlePricingClose}
          useData={uses}
          orderedUseIds={orderedUseIds}
          selectedUseId={selectedUseId}
          onOrderChanged={handleOrderChanged}
          onUpdates={handleUpdates}
          currentUser={currentUser}
        />
      )}
    </Box>
  );
};

Uses.propTypes = {
  matter: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired
};

export default Uses;
