import { useEffect, useState } from "react";
import { Alert } from "@mui/material";
import { DataTable } from "components/common/DataTable";
import { logService } from 'services/api';
import Loading from "utils/LoadingSpinner";

const LOG_COLUMNS = ['created_date', 'user', 'level', 'filename', 'function', 'line', 'message']

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const logsData = await logService.getLogs();
                console.log('Received logs:', logsData);
                setLogs(logsData.data)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching logs:', error);
                setError(error);
                setLoading(false);
            }
        };
        fetchLogs();
    }, []);

    return (
        <>
            {error && <Alert severity="error">{error.message || 'Error loading logs'}</Alert>}
            {loading ? (
                <Loading />
            ) : (
                <DataTable
                    data={logs}
                />
            )}
        </>
    );
};

export default Logs;

