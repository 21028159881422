import { useMemo } from 'react';
import { titleCase } from 'utils/dataUtils';
import { STEP_CONFIGS } from './reviewSteps';

export const useStepConfig = ({
    item,
    preambleText,
    paymentText,
    matters,
    reviewData,
    corrections,
    onStepUpdate,
    onCorrectionChange
}) => {
    const stepConfigs = useMemo(() => ({
        agreementType: {
            title: `Is this a ${titleCase(item.category || '')} agreement?`,
            subtitle: item.category
                ? `Detected Type: ${item.category}`
                : 'No agreement type detected',
            hasOriginalValue: Boolean(item.category),
            noValueTitle: 'Review Agreement Type',
            noValueSubtitle: 'Based on the excerpt below, please enter the correct agreement type',
            value: reviewData.agreementTypeCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: preambleText,
            onChange: (value, shouldAdvance) => onStepUpdate('agreementTypeCorrect', value, shouldAdvance),
            showCorrection: reviewData.agreementTypeCorrect === 'no',
            correctionType: 'auto_switch',
            correctionValue: corrections.agreementType,
            onCorrectionChange: (value) => onCorrectionChange('agreementType', value),
            category: item.category
        },
        matter: {
            title: 'Is the matter information correct?',
            subtitle: item.matter_name
                ? `Detected Matter: ${item.matter_name}`
                : 'No matter information detected',
            hasOriginalValue: Boolean(item.matter_name),
            noValueTitle: 'Review Matter Information',
            noValueSubtitle: 'Please review the agreement excerpt and enter the correct matter.',
            value: reviewData.matterCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: preambleText,
            onChange: (value, shouldAdvance) => onStepUpdate('matterCorrect', value, shouldAdvance),
            showCorrection: reviewData.matterCorrect === 'no',
            correctionType: 'matter',
            correctionValue: corrections.matter || null,
            onCorrectionChange: (value) => onCorrectionChange('matter', value),
            matterOptions: matters
        },
        effectiveDate: {
            title: 'Is the effective date correct?',
            subtitle: item.effective_date
                ? `Detected Date: ${item.effective_date}`
                : 'No effective date detected',
            hasOriginalValue: Boolean(item.effective_date),
            noValueTitle: 'Review Effective Date',
            noValueSubtitle: 'Please review the text and confirm if you can find the effective date',
            value: reviewData.effectiveDateCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: item.effective_date_span ? item.effective_date_span : preambleText,
            onChange: (value, shouldAdvance) => onStepUpdate('effectiveDateCorrect', value, shouldAdvance),
            showCorrection: reviewData.effectiveDateCorrect === 'no',
            correctionType: 'date',
            correctionValue: corrections.effectiveDate || null,
            onCorrectionChange: (value) => onCorrectionChange('effectiveDate', value)
        },
        expirationDate: {
            title: 'Is the expiration date correct?',
            subtitle: item.expiration_date
                ? `Detected Date: ${item.expiration_date}`
                : 'No expiration date detected',
            hasOriginalValue: Boolean(item.expiration_date),
            noValueTitle: 'Review Expiration Date',
            noValueSubtitle: 'Please review the text and confirm if you can find the expiration date',
            value: reviewData.expirationDateStatus,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: item.expiration_date_span || '',
            onChange: (value, shouldAdvance) => onStepUpdate('expirationDateStatus', value, shouldAdvance),
            showCorrection: reviewData.expirationDateStatus === 'no',
            correctionType: 'expiration_date',
            correctionValue: corrections.expirationDate || null,
            onCorrectionChange: (value) => onCorrectionChange('expirationDate', value),
            noExpiration: corrections.noExpiration || false,
            onNoExpirationChange: (value) => onCorrectionChange('noExpiration', value)
        },
        paymentDate: {
            title: 'Is the payment due date correct?',
            subtitle: item.payment_due
                ? `Detected Payment Date: ${item.payment_due}`
                : 'No payment due date detected',
            hasOriginalValue: Boolean(item.payment_due),
            noValueTitle: 'Review Payment Due Date',
            noValueSubtitle: 'Please review the text and confirm if you can find the payment due date',
            value: reviewData.paymentDueDateCorrect,
            options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
            ],
            text: paymentText,
            onChange: (value, shouldAdvance) => onStepUpdate('paymentDueDateCorrect', value, shouldAdvance),
            showCorrection: reviewData.paymentDueDateCorrect === 'no',
            correctionType: 'date',
            correctionValue: corrections.paymentDate || null,
            onCorrectionChange: (value) => onCorrectionChange('paymentDate', value)
        }
    }), [
        item,
        preambleText,
        paymentText,
        matters,
        reviewData,
        corrections,
        onStepUpdate,
        onCorrectionChange
    ]);

    const steps = useMemo(() => {
        let steps = [...STEP_CONFIGS.base];
        if (item.category && STEP_CONFIGS[item.category]) {
            steps = [...steps, ...STEP_CONFIGS[item.category]];
        }
        return steps;
    }, [item.category]);

    return {
        stepConfigs,
        steps
    };
}; 