import { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Container
} from "@mui/material";
import MatterTabs from "./MatterTabs";
import { ClickableTypography } from "components/common/Style/ClickableTypography";
import { StyledPaper } from "components/common/Style/StyledPaper";
import { matterService } from "services/api";
import LoadingSpinner from "utils/LoadingSpinner";


export const TopPageGrid = ({ matter }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle1">Comm Cycle</Typography>
      <Typography variant="body1" color="primary.main">
        {matter.comm_cycle}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle1">Stage</Typography>
      <Typography variant="body1" color="primary.main">
        {matter.stage}
      </Typography>
    </Grid>
    {matter.account_block && (
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="subtitle1">Block</Typography>
        <Typography variant="body1" color="primary.main">
          {matter.account_block}
        </Typography>
      </Grid>
    )}
    <Grid item xs={12} sm={6} md={3}>
      <Typography variant="subtitle1">Status</Typography>
      <Typography variant="body1" color="primary.main">
        {matter.status}
      </Typography>
    </Grid>
  </Grid >
);

const MatterDetail = () => {
  const [matter, setMatter] = useState([]);
  const [emails, setEmails] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const location = useLocation();
  const matterId = location.state?.matterId || id;

  const navigate = useNavigate();

  const tabsRef = useRef(null);

  // Scroll to the tabs section
  // Passed to the Uses component
  // Activates when the buttons are clicked
  const scrollToTabs = () => {
    tabsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchMatterDetails = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await matterService.getMatterDetails(matterId);

        const matter = response.data.items;

        console.log(response);
        setMatter(matter.matter);
        setEmails(matter.emails);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMatterDetails();
  }, [matterId]);

  const handleAccountClick = () => {
    if (matter && matter.account_id) {
      navigate(`/account/${matter.account_id}`);
    }
  };

  // Scroll to the tabs section
  // Activates when the buttons in the Uses tab are clicked
  // Or when the tab number and view name is in the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const view = searchParams.get("view");
    const tab = searchParams.get("tab");

    if (!isLoading && matter && view && tab === "1") {
      // Increase the delay to ensure content is rendered
      setTimeout(() => {
        scrollToTabs();
      }, 1000); // Changed from 100 to 500ms
    }
  }, [isLoading, matter, location.search]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          pt: 4,
        }}
      >
        <LoadingSpinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{
        height: "100%",
        width: "100%",
        pt: 4,
      }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  if (!matter) {
    return (
      <Box sx={{ p: 5, width: "100%" }}>
        <Typography variant="h6">No matter details found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "100%" }}>
      <Container maxWidth={false}>
        <StyledPaper elevation={3} sx={{ p: 4, mb: 4 }}>
          <ClickableTypography
            variant="h4"
            gutterBottom
            color="text.secondary"
            onClick={handleAccountClick}
          >
            {matter.matter}
          </ClickableTypography>
          <Typography variant="h6" gutterBottom>
            {matter.client}
          </Typography>

          <Divider sx={{ my: 2 }} />

          <TopPageGrid matter={matter} />
        </StyledPaper>
        <div ref={tabsRef}>
          <MatterTabs matter={matter} emails={emails} scrollToTabs={scrollToTabs} />
        </div>
      </Container>
    </Box >
  );
};

export default MatterDetail;
