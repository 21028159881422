import React from 'react';

class VideoErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <div>Error loading video. Please try again.</div>;
    }
    return this.props.children;
  }
}

export default VideoErrorBoundary;