import { useEffect, useState } from "react";
import { userHistoryService } from "services/api";

import { format, parseISO } from 'date-fns';
import {
    DescriptionOutlined,
    ImageOutlined,
    TimerOutlined
} from '@mui/icons-material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import { Container, Typography, CircularProgress, Card, Box, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from 'themes/Palette';


// Moved to a separate constant for better maintenance
const TASK_NAMES = {
    export_report: 'Export Report',
    updown: 'Up Down',
    ten_seconds: 'Ten Seconds',
};

const taskName = (task) => TASK_NAMES[task] || task;

const getTaskIcon = (taskType) => {
    switch (taskType) {
        case 'export_report':
            return <DescriptionOutlined />;
        case 'updown':
            return <ImageOutlined />;
        case 'ten_seconds':
            return <TimerOutlined />;
        default:
            return <DescriptionOutlined />;
    }
};

const MUITimelineItem = ({ item, isLast }) => (
    <TimelineItem>
        <TimelineSeparator>
            <TimelineDot color={item.success ? 'primary' : 'error'} >
                {getTaskIcon(item.function)}
            </TimelineDot>
            {!isLast && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
            <Typography variant="h6" component="h3">
                {item.success ? taskName(item.function) : taskName(item.function) + ' - ERROR'}
            </Typography>
            {item.result && item.result.data_name && (
                <Typography variant="body2">
                    {item.result.data_name}
                </Typography>
            )}
            <Typography variant="caption" component="time">
                {format(parseISO(item.start_time), 'h:mm a')}
            </Typography>
        </TimelineContent>
    </TimelineItem >
);

// And modify the StyledTimeline to ensure proper vertical alignment
const StyledTimeline = styled(Timeline)(({ theme }) => ({
    padding: theme.spacing(2, 0),
    [`& .MuiTimelineItem-root`]: {
        '&:before': {
            display: 'none',
        }
    },
    [`& .MuiTimelineContent-root`]: {
        flex: 4, // This helps align all content in the same column
    },
    [`& .MuiTimelineSeparator-root`]: {
        flex: 0, // Keep the separators (dots and lines) compact
    }
}));

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
    '&:before': {
        // Remove this flex: 0 setting as it breaks the column alignment
        padding: theme.spacing(0, 2), // Add some horizontal padding instead
    },
}));

const StyledCard = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
}));


// The DateGroup component remains the same but ensures items align vertically
const DateGroup = ({ date, items }) => (
    <Box className="date-group" style={{ width: '100%' }}>
        <Typography variant="h6" component="h2" className="date-header">
            {format(parseISO(date), 'EEEE, MMMM d, yyyy')}
        </Typography>
        <StyledTimeline>
            {items.map((item, index) => (
                <MUITimelineItem
                    key={`${item.start_time}-${index}`}
                    item={item}
                    isLast={index === items.length - 1}
                />
            ))}
        </StyledTimeline>
    </Box>
);

const UserHistory = () => {
    const [groupedHistory, setGroupedHistory] = useState({});
    const [loading, setLoading] = useState(true);
    const { theme } = useTheme();

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await userHistoryService.getUserHistory();
                const grouped = response.data.reduce((acc, item) => {
                    const date = item.start_time.split('T')[0];
                    if (!acc[date]) acc[date] = [];
                    acc[date].push(item);
                    return acc;
                }, {});
                setGroupedHistory(grouped);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user history:', error);
            }
        };

        fetchHistory();
    }, []);

    if (loading) return (
        <Container maxWidth={false}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        </Container>
    );

    return (
        <Container maxWidth={false}>
            {Object.keys(groupedHistory).length === 0 ? (
                <Typography variant="h5" color="text.secondary">
                    No history found
                </Typography>
            ) : (
                Object.entries(groupedHistory)
                    .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
                    .map(([date, items]) => (
                        <StyledCard variant="outlined">
                            <CardContent>
                                <DateGroup
                                    date={date}
                                    items={items}
                                />
                            </CardContent>
                        </StyledCard>
                    ))
            )}
        </Container>
    );
};

export default UserHistory;
