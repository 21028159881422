export const parseTimestamp = (timestamp) => {
    const parts = timestamp.split(':').map(parseFloat);
    return parts[0] * 3600 + parts[1] * 60 + parts[2];
};

// Helper functions to provide human-readable state descriptions
export const getNetworkStateDescription = (state) => {
    const states = {
        0: 'NETWORK_EMPTY - No data has been loaded',
        1: 'NETWORK_IDLE - Network is idle',
        2: 'NETWORK_LOADING - Network is loading data',
        3: 'NETWORK_NO_SOURCE - No video source found'
    };
    return states[state] || `Unknown network state: ${state}`;
};

export const getReadyStateDescription = (state) => {
    const states = {
        0: 'HAVE_NOTHING - No information about media',
        1: 'HAVE_METADATA - Metadata has been loaded',
        2: 'HAVE_CURRENT_DATA - Data for current position available',
        3: 'HAVE_FUTURE_DATA - Data for current and next frame available',
        4: 'HAVE_ENOUGH_DATA - Enough data available for playback'
    };
    return states[state] || `Unknown ready state: ${state}`;
};