// TollingAgreementFields.js
import React, { useState } from 'react';
import {
    Typography,
    FormControlLabel,
    Checkbox,
    Box,
} from '@mui/material';
import { DateField, ExecutionStatusField, NoExpirationField } from './FormFields';

const TollingAgreementFields = ({ onDataChange, errors }) => {
    const [formData, setFormData] = useState({
        effectiveDate: '',
        expirationDate: '',
        noExpiration: false,
        executionStatus: ''
    });

    const handleChange = (field) => (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const newFormData = {
            ...formData,
            [field]: value
        };

        // Clear expiration date when noExpiration is checked
        if (field === 'noExpiration' && value === true) {
            newFormData.expirationDate = '';
        }

        setFormData(newFormData);
        onDataChange(newFormData);
    };

    return (
        <Box mt={2}>
            <Typography variant="h6" gutterBottom>
                Tolling Agreement Fields
            </Typography>
            <DateField
                label="Effective Date"
                value={formData.effectiveDate}
                onChange={handleChange('effectiveDate')}
                error={!!errors.effectiveDate}
                helperText={errors.effectiveDate}
                required={true}
            />
            <NoExpirationField
                value={formData.noExpiration}
                onChange={handleChange('noExpiration')}
            />
            {!formData.noExpiration && (
                <DateField
                    label="Expiration Date"
                    value={formData.expirationDate}
                    onChange={handleChange('expirationDate')}
                    error={!!errors.expirationDate}
                    helperText={errors.expirationDate}
                    required={true}
                    disabled={formData.noExpiration}
                />
            )}
            <ExecutionStatusField
                value={formData.executionStatus}
                onChange={handleChange('executionStatus')}
                error={!!errors.executionStatus}
                helperText={errors.executionStatus}
                required={true}
            />
        </Box>
    );
};

export default TollingAgreementFields;