import { Launch as LaunchIcon } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { TYPE_ICONS } from '../utils/styles';
import DetailView from './DetailView';
import { format } from 'date-fns';
import { Notes } from 'pages/Pricing/UserNotes';

const generateTitle = (item) => {
    return item.title;
};

const generateDescription = (item) => {
    return 'Partner review required for pricing updates';
};

const generateMatterLink = (item) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Link href={`/matter/${item.matter_id}?view=pricing&tab=1`} sx={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                {item.matter}
            </Link>
            <LaunchIcon sx={{ fontSize: 14, opacity: 0.6, color: 'primary.main' }} />
        </Box>
    );
};

const generateFieldsList = (item) => {
    const fields = {
        'Matter Page': generateMatterLink(item),
        'Proposed Offer': item.proposedOffer,
        'Number of Uses': item.numberOfUses,
        'Completed By': item.completedBy,
        'Completed Date': item.completedDate,

    };

    return fields;
};

const PartnerPricingReviewDetail = ({ item, onClose }) => {
    const theme = useTheme();
    const IconComponent = TYPE_ICONS[item.type] || TYPE_ICONS.default;

    const GradientTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 700,
        fontSize: '1.35rem',
        background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        letterSpacing: '-0.02em'
    }));

    return (
        <DetailView onClose={onClose} item={item}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 3
            }}>
                <IconComponent sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    opacity: 0.9
                }} />
                <GradientTypography>
                    {generateTitle(item)}
                </GradientTypography>
            </Box>

            <Box sx={{ pl: '2.6rem' }}>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 3,
                        whiteSpace: 'pre-line',
                        color: alpha(theme.palette.text.primary, 0.87),
                        lineHeight: 1,
                        letterSpacing: '0.01em',
                    }}
                >
                    {generateDescription(item)}
                </Typography>

                <Box sx={{
                    display: 'grid',
                    gap: 1.5,
                    mb: 3,
                    pt: 2,
                    borderTop: `1px solid ${alpha(theme.palette.divider, 0.08)}`
                }}>
                    {Object.entries(generateFieldsList(item)).map(([label, value]) => (
                        <Box key={label} sx={{ display: 'flex', gap: 1 }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: alpha(theme.palette.text.primary, 0.6),
                                    fontWeight: 600,
                                    minWidth: '120px'
                                }}
                            >
                                {label}:
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: alpha(theme.palette.text.primary, 0.87),
                                }}
                            >
                                {value}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
            {item.notes && (
                <Notes notes={item.notes} theme={theme} />
            )}

        </DetailView>
    );
};

export default PartnerPricingReviewDetail;