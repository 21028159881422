import { Typography, Box } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { TYPE_ICONS } from '../utils/styles';
import { TextBox } from '../utils/TextBox';
import DetailView from './DetailView';
import { Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';


const generateDescription = (item) => {
    const daysText = item.daysAgo === 1 ? 'day' : 'days';
    return `It's been ${item.daysAgo} ${daysText} since our last communication.`;
};
const generateMatterLink = (item) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Link href={`/matter/${item.matter_id}`} sx={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                {item.matter}
            </Link>
            <LaunchIcon sx={{ fontSize: 14, opacity: 0.6, color: 'primary.main' }} />
        </Box>
    );
};

const generateFieldsList = (item) => {
    const fields = {
        'Last Communication Date': item.lastCommDate,
        'Communication Type': item.commType,
        'Matter Page': generateMatterLink(item)
    };

    return fields;
};

const LatestCommsDetail = ({ item, onClose }) => {
    const theme = useTheme();
    const IconComponent = TYPE_ICONS[item.type] || TYPE_ICONS.default;

    const GradientTypography = styled(Typography)(({ theme }) => ({
        fontWeight: 600,
        background: `linear-gradient(145deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: theme.spacing(1)
    }));

    const handleComplete = () => {
        if (onClose) onClose();
    };

    return (
        <DetailView
            onClose={onClose}
            item={item}
            onComplete={handleComplete}
        >
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                mb: 3
            }}>
                <IconComponent sx={{
                    fontSize: '1.5rem',
                    color: theme.palette.primary.main,
                    opacity: 0.9

                }} />
                <GradientTypography variant="h6">
                    {item.matter_name}
                </GradientTypography>
            </Box>
            <Typography
                variant="body1"
                sx={{
                    mb: 3,
                    whiteSpace: 'pre-line',
                    color: alpha(theme.palette.text.primary, 0.87),
                    lineHeight: 1,
                    letterSpacing: '0.01em',
                }}
            >
                {generateDescription(item)}
            </Typography>

            <Box sx={{
                display: 'grid',
                gap: 1,
                mb: 3,
                pt: 2,
                borderTop: `1px solid ${alpha(theme.palette.divider, 0.08)}`
            }}>
                {Object.entries(generateFieldsList(item)).map(([label, value]) => (
                    <Box key={label} sx={{ display: 'flex', gap: 1 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                color: alpha(theme.palette.text.primary, 0.6),
                                fontWeight: 600,
                                minWidth: '120px'
                            }}
                        >
                            {label}:
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: alpha(theme.palette.text.primary, 0.87),
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>
                ))}
            </Box>
            {item.textValue && (
                <>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            mb: 3,
                            color: alpha(theme.palette.text.primary, 0.7),
                            fontWeight: 400
                        }}
                    >
                        Below is a copy of the last recorded communication in this matter.
                    </Typography>
                    <TextBox text={item.textValue} />
                </>
            )}
        </DetailView>
    );
};

export default LatestCommsDetail;