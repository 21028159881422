import React, { useMemo } from 'react';
import { Box, Fade, Typography, alpha } from '@mui/material';
import { format, isToday } from 'date-fns';
import {
    TimelineCard,
    IconContainer,
    SecondaryText
} from './utils/styles';
import { useTheme } from '@mui/material/styles';
import { useTimelineContext } from './TimelineContext';
import { formatDateWithWeekday } from 'utils/dataUtils';
const EmptyFeed = ({ currentDate, theme }) => (
    <Box sx={{
        overflowY: 'auto',
        height: '100vh',
        pr: 2,
        pt: 2,
        borderRight: `0px solid ${alpha(theme.palette.common.white, 0.1)}`,
        pl: '2.6rem',
        color: theme.palette.error.main,
    }}>
        <Typography
            variant="h5"
            sx={{
                color: alpha(theme.palette.text.primary, 0.7),
                fontWeight: 400,
                mb: 1
            }}>
            {formatDateWithWeekday(currentDate)}
        </Typography>
        <Typography
            variant="body1"
            sx={{
                color: alpha(theme.palette.text.primary, 0.5),
            }}>
            Nothing in your feed today
        </Typography>
    </Box>
);

const TimelineItem = React.memo(({ item, isSelected, onItemClick, theme }) => {
    const Icon = item.icon;

    return (
        <TimelineCard
            priority={item.priority}
            selected={isSelected}
            onClick={() => onItemClick(item)}
            sx={{
                transition: 'all 0.2s ease-in-out',
                ...(isSelected && {
                    boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
                    bgcolor: alpha(theme.palette.primary.main, 0.08),
                })
            }}

        >
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <IconContainer>
                    <Icon />
                </IconContainer>

                <Box sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                        <Typography variant="subtitle1">
                            {item.title}
                        </Typography>
                        <SecondaryText>
                            {item.time}
                        </SecondaryText>
                    </Box>

                    <SecondaryText>
                        {item.description}
                    </SecondaryText>
                </Box>
            </Box>
        </TimelineCard>
    );
});

const TimelineSection = ({ timelineData, transitioning, currentDate }) => {
    const theme = useTheme();
    const { selectedItem, handleItemClick } = useTimelineContext();

    const todayData = useMemo(() => {
        const day = timelineData.find(day => isToday(day.date));
        if (!day) return null;

        return {
            ...day,
            items: day.items.filter(item => !item.complete)
        };
    }, [timelineData]);

    return (
        <Box sx={{ overflowY: 'auto', height: '100vh', pr: 2, pt: 2 }}>
            <Box>
                {todayData && (
                    <Box sx={{ mb: 4 }}>
                        {todayData.items.length > 0 ? (
                            todayData.items.map((item, index) => (
                                <Fade
                                    key={item.id || index}
                                    in={true}
                                    timeout={200}
                                    unmountOnExit
                                >
                                    <div style={{ animationFillMode: 'forwards' }}>
                                        <TimelineItem
                                            item={item}
                                            isSelected={selectedItem?.id === item.id}
                                            onItemClick={handleItemClick}
                                            theme={theme}
                                        />
                                    </div>
                                </Fade>
                            ))
                        ) : (
                            <EmptyFeed currentDate={currentDate} theme={theme} />
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default React.memo(TimelineSection);

