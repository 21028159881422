export const combineTextByParagraphClass = (fileMd, paragraphClass) => {
    if (!fileMd || !Array.isArray(fileMd)) return '';

    return fileMd
        .filter(row => row.paragraph_class === paragraphClass)
        .map(row => row.text)
        .join('\n\n')
        .trim();
};

export const formatStepLabel = (label) => {
    return label
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
};

export const formatDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

export const formatDateForApi = (date) => {
    if (!date) return null;
    return new Date(date).toISOString().split('T')[0];
}; 